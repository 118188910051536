// Import libraries
import React, { useEffect, useState } from 'react';

// Import ag-grid
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

// Import utility functions
import db from '../../config/firebaseConfig'; 

// Import Firestore functions
import { addDoc, collection, serverTimestamp, query, where, getDocs, updateDoc } from "firebase/firestore";

const ProposalsDashboard = ({ 
        authUser,
        setCurrentProposalId, 
        currentClientOrganization,
        currentProposalId,        
        grantProposals,
        refreshGrantProposals,
        setShowAddMoreGrantsModal,
    }) => {    

    const [goalAmount, setGoalAmount] = useState(0); 

    // Fetch goal from Firestore when component mounts
    useEffect(() => {
        if (currentClientOrganization && currentClientOrganization.id) {
            const fetchGoal = async () => {
                try {
                    const goalsQuery = query(
                        collection(db, "goals"),
                        where("organizationId", "==", currentClientOrganization.id)
                    );
                    const querySnapshot = await getDocs(goalsQuery);

                    if (!querySnapshot.empty) {
                        const goalDoc = querySnapshot.docs[0];
                        setGoalAmount(goalDoc.data().goalAmount);
                    }
                } catch (error) {
                    console.error("Error fetching goal from Firestore: ", error);
                }
            };

            fetchGoal();
        }
    }, [currentClientOrganization]);

    // When user changes or no document ID currently set, fetch grant proposals for current user
    useEffect(() => {            
        refreshGrantProposals();
    }, [authUser, currentProposalId === null]);    

    useEffect(() => {
        setCurrentProposalId(null);
    }, []);

    // Main function to handle when user selects a document
    const handleGrantProposalSelect = (proposal) => {
        setCurrentProposalId(proposal.id);
    };    

    const totalWonAmount = grantProposals
        .filter(proposal => proposal.status === "Won" && proposal.amount != null)
        .reduce((sum, proposal) => sum + Number(proposal.amount), 0);

    const totalSubmittedAmount = grantProposals
        .filter(proposal => proposal.status === "Submitted")
        .reduce((sum, proposal) => sum + Number(proposal.amount), 0);

    const totalInProgressAmount = grantProposals
        .filter(proposal => proposal.status === "In Progress" && proposal.amount != null)
        .reduce((sum, proposal) => sum + Number(proposal.amount), 0);

    const totalSavedAmount = grantProposals
        .filter(proposal => proposal.status === "Saved" && proposal.amount != null)
        .reduce((sum, proposal) => sum + Number(proposal.amount), 0);


    const handleGoalClick = async () => {
        const newGoal = prompt("Enter a new goal amount:", goalAmount);
        if (newGoal !== null) {
            setGoalAmount(Number(newGoal));
            
            try {
                // Query to check if a goal already exists for the current organization
                const goalsQuery = query(
                    collection(db, "goals"),
                    where("organizationId", "==", currentClientOrganization.id)
                );
                const querySnapshot = await getDocs(goalsQuery);

                if (!querySnapshot.empty) {
                    // If a goal exists, update the first document found
                    const goalDoc = querySnapshot.docs[0];
                    await updateDoc(goalDoc.ref, {
                        goalAmount: Number(newGoal),
                        updatedAt: serverTimestamp() // Optionally update the timestamp
                    });
                    console.log("Goal updated in Firestore successfully.");
                } else {
                    // If no goal exists, create a new one
                    await addDoc(collection(db, "goals"), {
                        goalAmount: Number(newGoal),
                        organizationId: currentClientOrganization.id,
                        createdAt: serverTimestamp()
                    });
                    console.log("Goal saved to Firestore successfully.");
                }
            } catch (error) {
                console.error("Error saving or updating goal in Firestore: ", error);
            }
        }
    };

    const columnDefs = [
        { 
            headerName: "Grant Name", 
            field: "name", 
            sortable: true,
            cellStyle: { textAlign: 'left', fontWeight: 'bold' }, 
            flex: 2 
        },
        { 
            headerName: "Deadline", 
            field: "deadline", 
            sortable: true,
            filter: 'agDateColumnFilter',
            valueGetter: (params) => {
                return params.data.deadline ? new Date(params.data.deadline.seconds * 1000) : null;
            },
            valueFormatter: (params) => params.value ? new Date(params.value).toISOString().split('T')[0] : '',
            cellStyle: { textAlign: 'left' }, 
            flex: 1 
        },
        { 
            headerName: "Status", 
            field: "status", 
            sortable: true,
            cellStyle: { textAlign: 'left' }, 
            flex: 1 
        },
        { 
            headerName: "Amount ($)", 
            field: "amount", 
            sortable: true,
            valueFormatter: (params) => params.value ? `$${parseInt(params.value).toLocaleString()}` : '',
            cellStyle: { textAlign: 'right' }, 
            flex: 1 
        },
        { 
            headerName: "Notes", 
            field: "notes",
            cellStyle: { textAlign: 'left' }, 
            flex: 3 
        }
    ];

    // Add this function to apply custom styles to rows
    const getRowStyle = () => {
        return { cursor: 'pointer' };
    };

    return (
        <div className='p-12'>
            <div>
                <p className="text-3xl font-bold text-left">Overview</p>
                <div className="flex space-x-4 mt-5 text-left">
                    
                    <div className="bg-gray-400 text-black font-bold py-4 px-4 rounded w-1/5">
                        <p className="text-3xl">${totalSavedAmount ? totalSavedAmount.toLocaleString() : '0'}</p>
                        <p className="text-xs font-normal mt-2">Saved</p>
                    </div>
                    <div className="bg-gray-400 text-black font-bold py-4 px-4 rounded w-1/5">
                        <p className="text-3xl">${totalInProgressAmount ? totalInProgressAmount.toLocaleString() : '0'}</p>
                        <p className="text-xs font-normal mt-2">In Progress</p>
                    </div>
                    <div className="bg-gray-400 text-black font-bold py-4 px-4 rounded w-1/5">
                        <p className="text-3xl">${totalSubmittedAmount ? totalSubmittedAmount.toLocaleString() : '0'}</p>
                        <p className="text-xs font-normal mt-2">Submitted</p>
                    </div>
                    <div className="bg-indigo-500 text-white font-bold py-4 px-4 rounded w-1/5">
                        <p className="text-3xl">${totalWonAmount ? totalWonAmount.toLocaleString() : '0'}</p>
                        <p className="text-xs font-normal mt-2">Won</p>
                    </div>
                    <div 
                        className="bg-green-500 text-white font-bold py-4 px-4 rounded w-1/5"
                        onClick={handleGoalClick}
                        style={{ cursor: 'pointer' }} 
                    >
                        <p className="text-3xl">${goalAmount.toLocaleString()}</p>
                        <div className="flex justify-between mt-2">
                            <p className="text-xs font-normal">Goal</p>
                            <span className="text-xs">EDIT</span>
                        </div>
                        
                    </div>
                </div>
            </div>

            <div className="flex justify-between items-center mt-10">
                <p className="text-3xl font-bold text-left">Your Grant Tracker</p>
                <div className="flex space-x-4">
                    <button onClick={() => setShowAddMoreGrantsModal(true)} className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded">
                        Add Grants to Tracker
                    </button>
                </div>
            </div>

            <div className='ag-theme-alpine mt-12'  style={{  width: '100%' }}>
                <AgGridReact
                    rowData={grantProposals}
                    columnDefs={columnDefs}
                    onRowClicked={(event) => handleGrantProposalSelect(event.data)}
                    getRowStyle={getRowStyle} 
                    domLayout='autoHeight'
                />
            </div>
        </div>
    );
};

export default ProposalsDashboard;
