import React from 'react';
import gmLogoName from '../../assets/gmLogoName.png';

function PaywallComponent({
    onClose
}) {
    const handleGetAccessClick = () => {
        // Add your logic here
        console.log('Get Access Now button clicked');
        window.open('https://satodadj.gumroad.com/l/rlqzat', '_blank');
    };

    return (
        <div className='fixed inset-0 flex flex-col justify-center bg-gray-100 bg-opacity-80 backdrop-blur-md p-24 rounded-lg' style={{ zIndex: 2147483647 }}>
            <button 
                className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 font-bold py-2 px-4 rounded"
                onClick={onClose}
            >
                Close
            </button>
            <img src={gmLogoName} alt="GM Logo" className="w-40 mx-auto mt-6 mb-2" />
            <p className="text-center text-2xl mt-2">You need a Premium plan to access this functionality.</p>                 
            
            <div className="flex h-screen justify-center items-center space-x-16 py-6">
                <div className="bg-white w-2/5 h-full p-8 rounded-lg shadow-xl">
                    <h2 className="text-xl font-bold text-center">Starter</h2>
                    <p className="text-center text-sm mt-2 text-gray-500">For those getting started with GrantMagic</p>
                    <p className="text-4xl font-bold text-left mt-6">Free</p>
                    <p className="text-left text-sm mt-2 text-gray-500">In your spare time, find possible funders</p>
                    <hr className="my-4 border-t border-gray-200 w-full" />                                                            
                    <ul className="text-left text-md mt-4 space-y-2">
                        <li>✅ 1M+ Grant Awards Database</li>                        
                        <li>✅ AI Drafts for LOIs & Cover Letters</li>                        
                        <li>✅ Grant Tracker</li>
                        <li>❌ Personalized Grant Newsletter</li>
                        <li>❌ Priority Support</li>
                        <li>❌ Create multiple programs / organizations</li>
                        <li>❌ Dedicated account manager</li>
                    </ul>
                    <button className="bg-gray-200 text-white font-bold py-2 px-4 rounded mt-6 w-full mb-4 cursor-not-allowed">Already Joined</button>
                </div>
                <div className="bg-white w-2/5 h-full p-8 rounded-lg shadow-xl">
                    <h2 className="text-xl font-bold text-center">Premium</h2>
                    <p className="text-center text-sm mt-2 text-gray-500">For nonprofits ready to win more grant funding</p>
                    <p className="text-left mt-6"><span className="text-4xl font-bold">$5 / month</span><span className="text-xs ml-2 text-left mt-2 text-gray-500">billed annually</span></p>
                    
                    <p className="text-left text-sm mt-2 text-gray-500">Win more grants, for the price of a venti latte</p>
                    <hr className="my-4 border-t border-gray-200 w-full" />    
                    <ul className="text-left text-md mt-4 space-y-2">
                        <li>✅ 1M+ Grant Awards Database</li>                        
                        <li>✅ AI Drafts for LOIs & Cover Letters</li>                        
                        <li>✅ Grant Tracker</li>
                        <li>✅ Personalized Grant Newsletter</li>
                        <li>✅ Priority Support</li>
                        <li>❌ Create multiple programs / organizations</li>
                        <li>❌ Dedicated account manager</li>
                    </ul>                                                        
                    <button className="bg-indigo-500 text-white font-bold py-2 px-4 rounded mt-6 w-full" onClick={handleGetAccessClick}>Get Access Now</button>
                </div>
                <div className="bg-white w-2/5 h-full p-8 rounded-lg shadow-xl">
                    <h2 className="text-xl font-bold text-center">Enterprise</h2>
                    <p className="text-center text-sm mt-2 text-gray-500">For large organizations with advanced needs</p>
                    <p className="text-left mt-6"><span className="text-4xl font-bold">Contact Us</span></p>
                    
                    <p className="text-left text-sm mt-2 text-gray-500">Tailored solutions for your organization</p>
                    <hr className="my-4 border-t border-gray-200 w-full" />    
                    <ul className="text-left text-md mt-4 space-y-2">
                        <li>✅ 1M+ Grant Awards Database</li>                        
                        <li>✅ AI Drafts for LOIs & Cover Letters</li>                        
                        <li>✅ Grant Tracker</li>
                        <li>✅ Personalized Grant Newsletter</li>
                        <li>✅ Priority Support</li>
                        <li>✅ Create multiple programs / organizations</li>                        
                        <li>✅ Dedicated account manager</li>                                                                        
                    </ul>                                                        
                    <button className="bg-indigo-500 text-white font-bold py-2 px-4 rounded mt-6 w-full" onClick={() => window.open('https://tally.so/r/mDVa5j', '_blank')}>Contact Us</button>
                </div>
            </div>
            
        </div>                    
  );
}

export default PaywallComponent;
