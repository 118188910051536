import { createClient } from '@supabase/supabase-js';

// Initialize Supabase client
const supabaseUrl = 'https://jlukxfltibmxaonhripo.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImpsdWt4Zmx0aWJteGFvbmhyaXBvIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTcyOTYwOTI0NiwiZXhwIjoyMDQ1MTg1MjQ2fQ.azWLdoVXRgEggw9XoGQJUecPAzqxlg4rdP0LkBZpI3w';
const supabase = createClient(supabaseUrl, supabaseAnonKey);

export const fetchRFPsFromSupabase = async () => {
    console.log("Fetching RFPs from Supabase.");
    try {
        const { data, error } = await supabase
            .from('rfps') 
            .select('*');

        if (error) {
            throw error;
        }

        // console.log("RFPs from Supabase:", data);
        return data; 
    } catch (error) {
        console.error("Error fetching RFPs from Supabase:", error);
        throw error;
    }
};

export const fetchGrantmakingProgramsFromSupabase = async () => {
    console.log("Fetching grantmaking programs from Supabase.");
    try {
        const { data, error } = await supabase
            .from('grantmaking_programs')
            .select('*');
        if (error) {
            throw error;
        }
        return data;
    } catch (error) {
        console.error("Error fetching grantmaking programs from Supabase:", error);
        throw error;
    }
};

export const fetchSingleRFPFromSupabase = async (selectedOpportunityId) => {
    console.log("Fetching single RFP from Supabase for ID: ", selectedOpportunityId);
    try {
        const { data, error } = await supabase
            .from('rfps') 
            .select('*')
            .eq('airtable_id', selectedOpportunityId);

        if (error) {
            throw error;
        }

        console.log("SingleRFP from Supabase:", data);
        return data; 
    } catch (error) {
        console.error("Error fetching RFPs from Supabase:", error);
        throw error;
    }
};

export const fetchSingleGrantmakingProgramFromSupabase = async (selectedProgramId) => {
    console.log("Fetching single grantmaking program from Supabase for ID: ", selectedProgramId);
    
    try {
        const { data, error } = await supabase
            .from('grantmaking_programs')
            .select('*')
            .eq('id', selectedProgramId);
        if (error) {
            throw error;
        }
        return data;
    } catch (error) {
        console.error("Error fetching grantmaking programs from Supabase:", error);
        throw error;
    }
};

export const fetchSingleRFPAndCorrespondingScoreFromSupabase = async (selectedOpportunityId, programId) => {
    console.log("Fetching single RFP and corresponding scores from Supabase for ID: ", selectedOpportunityId, " and program ID: ", programId);
    try {
        const { data, error } = await supabase
            .from('rfps') 
            .select('*')
            .eq('airtable_id', selectedOpportunityId);

        if (error) {
            throw error;
        }

        const singleRfpScore = await fetchSingleRfpScoreFromSupabase(selectedOpportunityId, programId);

        console.log("SingleRFP from Supabase:", data);
        
        // Combine RFP data and score into a single object
        const combinedResult = {
            rfp: data,
            score: singleRfpScore // This may be null if no score is found
        };

        return combinedResult; // Return the combined object
    } catch (error) {
        console.error("Error fetching RFPs from Supabase:", error);
        throw error;
    }
};

// export const fetchSingleGrantmakingProgramAndCorrespondingScoreFromSupabase = async (grantmakingProgramId, grantseekingProgramId) => {
//     console.log("Fetching single grantmaking program and corresponding scores from Supabase for ID: ", grantmakingProgramId, " and grantseeking program ID: ", grantseekingProgramId);
//     try {
//         const { data, error } = await supabase
//             .from('grantmaking_programs') 
//             .select('*')
//             .eq('id', grantmakingProgramId)
//             // .eq('grantseeking_program_id', grantseekingProgramId);

//         if (error) {
//             throw error;
//         }

//         console.log("Fetched grantmaking program from Supabase:", data);

//         const singleProgramFitScore = await fetchSingleProgramFitScoreFromSupabase(grantmakingProgramId, grantseekingProgramId);

//         console.log("Single grantmaking program from Supabase:", data);
        
//         // Combine RFP data and score into a single object
//         const combinedResult = {
//             grantmakingProgram: data,
//             score: singleProgramFitScore // This may be null if no score is found
//         };

//         return combinedResult; // Return the combined object
//     } catch (error) {
//         console.error("Error fetching grantmaking programs from Supabase:", error);
//         throw error;
//     }
// };

export const fetchRfpScoresFromSupabase = async (programId) => {
    // console.log("Fetching RFP scores from Supabase for program: ", programId);
    try {
        const { data, error } = await supabase
            .from('rfp_scores') 
            .select('*')
            .eq('program_id', programId);

        if (error) {
            throw error;
        }
        console.log("RFP scores from Supabase:", data);        
        return data; // Return the fetched data
    } catch (error) {
        console.error("Error fetching RFP scores from Supabase:", error);
        throw error;
    }
};

export const fetchProgramFitScoresFromSupabase = async (programId) => {
    console.log("Fetching RFP scores from Supabase for program: ", programId);
    try {
        const { data, error } = await supabase
            .from('program_fit_scores') 
            .select('*')
            .eq('grantseeking_program_id', programId);

        if (error) {
            throw error;
        }
        console.log("Program fit scores from Supabase:", data);        
        return data; // Return the fetched data
    } catch (error) {
        console.error("Error fetching Program fit scores from Supabase:", error);
        throw error;
    }
};

export const fetchSingleProgramFitScoreFromSupabase = async (grantmakingProgramId, grantseekingProgramId) => {
    console.log("Fetching RFP scores from Supabase for single program ID: ", grantseekingProgramId, " and grantmaking program ID: ", grantmakingProgramId);
    try {
        const { data, error } = await supabase
            .from('program_fit_scores') 
            .select('*')
            .eq('grantseeking_program_id', grantseekingProgramId)
            .eq('grantmaking_program_id', grantmakingProgramId);

        if (error) {
            throw error;
        }
        console.log("Program fit scores from Supabase:", data);        
        return data; // Return the fetched data
    } catch (error) {
        console.error("Error fetching Program fit scores from Supabase:", error);
        throw error;
    }
};

export const fetchSingleRfpScoreFromSupabase = async (selectedOpportunityId, programId) => {
    // console.log("Fetching RFP scores from Supabase for program: ", programId);
    try {
        const { data, error } = await supabase
            .from('rfp_scores') 
            .select('*')
            .eq('rfp_id', selectedOpportunityId)
            .eq('program_id', programId);

        if (error) {
            throw error;
        }
        // console.log("RFP scores from Supabase:", data);        
        return data; // Return the fetched data
    } catch (error) {
        console.error("Error fetching single RFP score from Supabase:", error);
        throw error;
    }
};

export const fetchAwardsReceivedFromSupabase = async (selectedOrganizationEin) => {
    // console.log("Fetching awards from Supabase for organization EIN: ", selectedOrganizationEin);
    try {
        const { data, error } = await supabase
            .from('awards') // Updated table name to 'awards'
            .select('*')
            .eq('recipient_ein', selectedOrganizationEin); // Updated column and parameter

        if (error) {
            throw error;
        }
        console.log("Awards from Supabase:", data);
        return data; // Return the fetched data
    } catch (error) {
        console.error("Error fetching awards from Supabase:", error);
        throw error;
    }
};

export const fetchAwardsGrantedFromSupabase = async (selectedOrganizationEin) => {
    // console.log("Fetching awards granted from Supabase for organization EIN: ", selectedOrganizationEin);
    try {
        const { data, error } = await supabase
            .from('awards') // Updated table name to 'awards'
            .select('*')
            .eq('funder_ein', selectedOrganizationEin); // Updated column and parameter

        if (error) {
            throw error;
        }
        console.log("Awards granted from Supabase:", data);
        return data; // Return the fetched data
    } catch (error) {
        console.error("Error fetching awards granted from Supabase:", error);
        throw error;
    }
};

export const fetchLatest990FromSupabase = async (selectedOrganizationEin) => {
    if (!selectedOrganizationEin) {
        console.log("fetchLatest990FromSupabase() called, but selectedOrganizationEin not set. Skipping fetch.");
        return null;
    }
            
    try {
        console.log("Fetching latest 990 from Supabase for given FilerEin.");
        const { data, error } = await supabase
            .from('form_990s')
            .select('*')
            .eq('ein', selectedOrganizationEin)
            .order('return_ts', { ascending: false })
            .limit(1);
        if (error) {
            console.log("No matching 990 found.");
            return null;
        }
        const latest990 = data[0];
        console.log("Latest 990 from Supabase:", latest990);
        return latest990;
    } catch (error) {
        console.error("Error fetching latest 990 from Supabase:", error);
        return null;
    }
};

export const fetchNteeDefinitionsFromSupabase = async (searchTerm) => {
    console.log("Fetching NTEE definitions from Supabase for search term: ", searchTerm);
    try {
        const { data, error } = await supabase
            .from('ntee_definitions')
            .select('*')
            .or(`description.ilike.%${searchTerm}%,definition.ilike.%${searchTerm}%`); // Match on either column

        if (error) {
            throw error;
        }
        console.log("NTEE definitions from Supabase:", data);
        return data; // Return the fetched data
    } catch (error) {
        console.error("Error fetching NTEE definitions from Supabase:", error);
        throw error;
    }
};