import React from 'react';
import { deleteGrantDocumentFromFirestore, renameGrantDocumentInFirestore } from '../../utilityFunctions/firestoreUtilities';

const ProposalDocumentsSection = ({     
    setShowKnowledgeBaseModal,
    assistantFiles,
    setShowMfdComponentSelectorModal,
    proposalDocuments,
    setCurrentView,
    setCurrentGrantDocumentId,
    refreshProposalDocuments
}) => {

    // Handle when the add document button is clicked
    const addDocumentButtonClicked = () => {
        if (assistantFiles?.length > 0) {   
            setShowMfdComponentSelectorModal(true);
        } else {
            alert("No training data found. Please upload training data to enable AI-Generated Documents.");
            setShowKnowledgeBaseModal(true);
        }
    }    

    // Handle when document is clicked
    const handleEditDocumentButtonClicked = (documentId) => {
        setCurrentGrantDocumentId(documentId);
        setCurrentView("grantDocumentEditor");
    }

    return (
        <div className="py-2 h-screen">
            {(proposalDocuments?.length ?? 0) === 0 && (
                <div className='w-full p-12 bg-gray-100 text-center text-gray-500 my-6'>
                    <p className='text-lg font-bold'>Want help writing this grant?</p>
                    <p className='text-sm'>Daisy can generate first draft proposals instantly, and edit your writing to make it more effective.</p>
                    <div className='flex justify-center items-center space-x-4'>
                        <button onClick={addDocumentButtonClicked} className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded mt-6">Get Started</button>
                    </div>                    
                </div>
            )}
            
            {/* Components Section */}
            {(proposalDocuments?.length ?? 0) > 0 && (
                <div>
                    {proposalDocuments.map((document) => (
                        <div 
                            className='flex justify-between items-center w-full mt-6 bg-orange-100 p-4 rounded cursor-pointer'
                            onClick={() => handleEditDocumentButtonClicked(document.id)}
                        >
                            <div className='flex flex-col'>
                                <p className="text-xl font-bold text-left">{document.documentName}</p>
                                <p className="text-sm text-gray-500 text-left"> Created: {document.createdAt.toDate().toLocaleString()}</p>
                                <p className="text-sm text-gray-500 text-left">Updated: {document.updatedAt.toDate().toLocaleString()}</p>
                            </div>
                            <div className='flex space-x-4'>
                                <button onClick={(e) => {
                                    e.stopPropagation();
                                    if (window.confirm("Are you sure you want to delete this document?")) {
                                        deleteGrantDocumentFromFirestore(document.id);
                                        refreshProposalDocuments();                                    
                                    }
                                }} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">Delete</button>
                                <button onClick={(e) => {
                                    e.stopPropagation();
                                    const newName = prompt("Enter the new document name:");
                                    if (newName) {
                                        renameGrantDocumentInFirestore(document.id, newName);
                                        refreshProposalDocuments();
                                    }
                                }} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">Rename</button>
                            </div>                            
                        </div>
                    ))}
                    <div className='w-full p-12 bg-gray-100 text-center text-gray-500 my-6'>
                        <p className='text-lg font-bold'>Need more documents?</p>
                        <p className='text-sm'>In a matter of seconds, Daisy can generate first draft cover letters, proposals, and more.</p>
                        <button onClick={addDocumentButtonClicked} className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded mt-6">Generate</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ProposalDocumentsSection;