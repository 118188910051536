import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Import functions
import {  fetchClientProgramsFromFirestore, fetchGrantProposalByIdFromFirestore, fetchFilesMetadataFromFirestore, fetchGrantDocumentsFromFirestoreForProposal, fetchProposalComponentsFromFirestore, fetchAssistantDetailsFromFirestoreForOrganization, fetchClientOrganizationsFromFirestore, fetchGrantProposalsFromFirestoreForOrganization, createGrantProposalInFirestore, createGrantProposalComponentInFirestore, markFileAsDeletedInFirestore, uploadFileToFirebaseStorage, saveOpenAiFileIdToFirestore, saveFileMetadataToFirestore, fetchAssistantDetailsFromFirestore, fetchMembershipDetailsFromFirestore, fetchOrganizationDetailsFromFirestore, fetchMembershipDetailsFromFirestoreForOrganization } from '../utilityFunctions/firestoreUtilities';
import { fetchFilesFromOpenAI } from '../utilityFunctions/openAiUtilities';

// Import configs
import { AuthContext } from '../AuthContext';
import { auth } from '../config/firebaseConfig'; 

// Import components
import AwardsAdvancedSearch from './AwardsSearch/AwardsAdvancedSearch';
import OnboardingFlow from './HelperComponents/OnboardingFlow';
import LoadingModal from './HelperComponents/LoadingModal';
import ManageProposalModal from './OrganizerTools/ManageProposalModal';
import PaywallComponent from './HelperComponents/PaywallComponent';
import SideNavBar from './SideNavBar';
import LoggedOutWelcome from './AuthComponents/LoggedOutWelcome';
import ProposalsDashboard from './OrganizerTools/ProposalsDashboard';
import MfdComponentSelectorModal from './GrantWritingComponents/MfdComponentSelectorModal';
import ClientOrganizationsPickerModal from './ClientOrganizationsPickerModal';
import CreateProgramModal from './GrantDiscoveryComponents/CreateProgramModal';
import AccountSettings from './HelperComponents/AccountSettings';
import UserSimulator from './HelperComponents/UserSimulator';
import AddMoreGrantsModal from './OrganizerTools/AddMoreGrantsModal';
import MobileWelcome from './AuthComponents/MobileWelcome';
import SelectedProposalPage from './GrantWritingComponents/SelectedProposalPage';
import RfpsSearch from './GrantDiscoveryComponents/RrfpsSearch';
import OrganizationProfilePage from './GrantDiscoveryComponents/OrganizationProfilePage';
import RfpProfilePage from './GrantDiscoveryComponents/RfpProfilePage';
import SaveToTrackerModal from './OrganizerTools/SaveToTrackerModal';
import KnowledgeBaseModal from './GrantWritingComponents/KnowledgeBaseModal';
import GrantDocumentEditor from './GrantWritingComponents/GrantDocumentEditor';
import GrantDocumentVersionHistoryModal from './GrantWritingComponents/GrantDocumentVersionHistoryModal';
import AiComponentEditorModal from './GrantWritingComponents/AiComponentEditorModal';

// Utility function to detect mobile devices
const isMobileDevice = () => {
  return /Mobi|Android/i.test(navigator.userAgent);
};

function Home() {

  const { authUser } = useContext(AuthContext);  
  const navigate = useNavigate();
  // const location = useLocation();
  const { ein } = useParams();
  const { opportunityId } = useParams();
  const { grantmakingProgramId } = useParams();


  // Top level state variables
  const [currentView, setCurrentView] = useState('proposalsDashboard'); 
  const [membershipDetails, setMembershipDetails] = useState(null);
  const [clientOrganizationsBelongedTo, setClientOrganizationsBelongedTo] = useState([]);
  const [currentClientOrganization, setCurrentClientOrganization] = useState(null);
  const [organizationPrograms, setOrganizationPrograms] = useState([]);  
  const [assistantId, setAssistantId] = useState(null);
  const [assistantVectorStoreId, setAssistantVectorStoreId] = useState(null);
  const [assistantFiles, setAssistantFiles] = useState([])

  // Grant discovery state variables
  const [selectedOrganizationEin, setSelectedOrganizationEin] = useState(ein);
  // const [selectedOpportunity, setSelectedOpportunity] = useState()
  // const [selectedOpportunityId, setSelectedOpportunityId] = useState(opportunityId);
  const [selectedGrantmakingProgram, setSelectedGrantmakingProgram] = useState(null)
  const [selectedGrantmakingProgramId, setSelectedGrantmakingProgramId] = useState(null)  
  const [currentProgram, setCurrentProgram] = useState(null)
  const [programToModify, setProgramToModify] = useState(null)
  
  // Grant writing state variables
  const [grantProposals, setGrantProposals] = useState([]);
  const [currentProposal, setCurrentProposal] = useState();
  const [currentProposalId, setCurrentProposalId] = useState(); 
  const [proposalDocuments, setProposalDocuments] = useState([]);
  const [currentGrantDocumentId, setCurrentGrantDocumentId] = useState(null);
  const [highlightedTextForEditing, setHighlightedTextForEditing] = useState(null);

    // Modal state variables
  const [showLoadingModal, setShowLoadingModal] = useState(false)
  const [showManageProposalModal, setShowManageProposalModal] = useState(false)  
  const [showPaywallModal, setShowPaywallModal] = useState(false)
  const [showClientOrganizationsPickerModal, setShowClientOrganizationsPickerModal] = useState(false)
  const [showCreateProgramModal, setShowCreateProgramModal] = useState(false)
  const [showAddMoreGrantsModal, setShowAddMoreGrantsModal] = useState(false)
  const [showOnboardingFlow, setShowOnboardingFlow] = useState(false);
  const [showMfdComponentSelectorModal, setShowMfdComponentSelectorModal] = useState(false);
  const [showSaveToTrackerModal, setShowSaveToTrackerModal] = useState(false);
  const [showKnowledgeBaseModal, setShowKnowledgeBaseModal] = useState(false);
  const [showGrantDocumentVersionHistoryModal, setShowGrantDocumentVersionHistoryModal] = useState(false);
  const [showAiComponentEditorModal, setShowAiComponentEditorModal] = useState(false);

  // When authUser changes, get client organizations
  useEffect(() => {        
    if (authUser) { 
      if (authUser.uid) { 
        fetchClientOrganizationsBelongedTo(authUser.uid);
      }
    }
  }, [authUser]); 

  // Define current client organization
  useEffect(() => {
    if (clientOrganizationsBelongedTo.length > 0) {
      setCurrentClientOrganization(clientOrganizationsBelongedTo[0]) 
      console.log("currentClientOrganization set to: ", currentClientOrganization)
    }
  }, [clientOrganizationsBelongedTo]);

  // Once current client organization set, fetch programs, membership details, and refresh grant proposals
  useEffect(() => {
    if (currentClientOrganization) {
      fetchProgramsForOrganization(currentClientOrganization.id);
      fetchMembershipDetails()
      refreshGrantProposals()
      
      const fetchAssistantDetails = async () => {
        const assistantDetails = await fetchAssistantDetailsFromFirestoreForOrganization(currentClientOrganization.id);
        console.log("assistantDetails: ", assistantDetails);
        
        if (assistantDetails) {
          setAssistantId(assistantDetails.assistantId);
          setAssistantVectorStoreId(assistantDetails.assistantVsId);
        } else {
          console.error("No assistant details found for the organization.");
        }
      }
      fetchAssistantDetails()
    }
  }, [currentClientOrganization, assistantId]);

  // Define current program
  useEffect(() => {
    // if (organizationPrograms.length > 0) {
    if (!currentProgram && organizationPrograms.length > 0) {
        setCurrentProgram(organizationPrograms[0]);
        // console.log("Changed current program to: ", currentProgram);
    }
}, [currentProgram, organizationPrograms]);

  // If selectedOrganizationEin changes, show OpportunityAndFunderModal
  useEffect(() => {
    if (selectedOrganizationEin) {      
      // setShowOpportunityAndFunderModal(true);
      setCurrentView('organizationProfilePage');
    }
  }, [selectedOrganizationEin]);

  useEffect(() => {
    if (selectedGrantmakingProgramId) {
      setCurrentView('rfpProfilePage');
    }
  }, [selectedGrantmakingProgramId]);

  // If currentProposal changes, set currentView to selectedProposalPage
  useEffect(() => {
    if (currentProposalId) {
      setCurrentView('selectedProposalPage');
      refreshProposalDocuments();
    }
  }, [currentProposalId]);

  // Update URL when selectedOrganizationEin changes
  useEffect(() => {
    if (selectedOrganizationEin && selectedOrganizationEin !== ein) {
      navigate(`/profiles/organization/${selectedOrganizationEin}`);
    }
  }, [selectedOrganizationEin, navigate, ein]);

  // Update URL when selectedGrantmakingProgramId changes
  useEffect(() => {
    if (selectedGrantmakingProgramId) {
      console.log("Updating URL when selectedGrantmakingProgramId changes: ", selectedGrantmakingProgramId);
      navigate(`/grantmakingprograms/${selectedGrantmakingProgramId}`);
    }
  }, [selectedGrantmakingProgramId, navigate]);

  // Add this useEffect to set the current view based on the URL and handle navigation when currentView changes
  useEffect(() => {
    if (window.location.pathname === '/awards/search') {
      setCurrentView('awardsAdvancedSearch');
    } else if (window.location.pathname === '/grantmakingprograms') {
      setCurrentView('rfpsSearch');
    } else if (currentView !== 'organizationProfilePage' && currentView !== 'rfpProfilePage' && currentView !== 'awardsAdvancedSearch' && currentView !== 'rfpsSearch') {
      navigate('/'); 
    }
  }, [currentView, navigate]);

  // Other Functions
  async function fetchAssistantFiles(assistantVectorStoreId) {
    if (!assistantVectorStoreId) {
        console.log("assistantVectorStoreId is null, skipping fetch.");
        return;
    }

    try {
        const vectorStoreFiles = await fetchFilesFromOpenAI(assistantVectorStoreId);
        const vectorStoreFilesWithMetadata = await fetchFilesMetadataFromFirestore(vectorStoreFiles);            
        setAssistantFiles(vectorStoreFilesWithMetadata);
        console.log("Successfully fetched vector store files with metadata:", vectorStoreFilesWithMetadata);
    } catch (error) {
        console.error("Error fetching vector store files: ", error);
    }
  }
  
  const fetchProgramsForOrganization = async (organizationId) => {
    console.log("Fetching client programs for organization ID:", organizationId);
    const clientPrograms = await fetchClientProgramsFromFirestore(organizationId);
    console.log("Fetched client programs:", clientPrograms);
    setOrganizationPrograms(clientPrograms);

    // Show onboarding flow if no programs are found
    if (!clientPrograms || clientPrograms.length === 0) {
      setShowOnboardingFlow(true);
    } else {
      setShowOnboardingFlow(false);
    }

    return clientPrograms;
  };


  const refreshGrantProposals = async () => {
    if (!currentClientOrganization || !currentClientOrganization.id) {
        console.error("Current client organization is not set.");
        setShowOnboardingFlow(true);
        return;
    }
    const fetchedGrantProposals = await fetchGrantProposalsFromFirestoreForOrganization(currentClientOrganization.id);
    console.log("fetchedGrantProposals: ", fetchedGrantProposals);
    setGrantProposals(fetchedGrantProposals);
};

const refreshProposalDocuments = async () => {
  const fetchedProposalDocuments = await fetchGrantDocumentsFromFirestoreForProposal(currentProposalId);
  console.log("fetchedProposalDocuments: ", fetchedProposalDocuments);
  setProposalDocuments(fetchedProposalDocuments);
}
  
  // Main function to fetch clientOrganizations which user belongs to
  const fetchClientOrganizationsBelongedTo = async (uid) => {
    try {
      const clientOrganizations = await fetchClientOrganizationsFromFirestore(uid);
        setClientOrganizationsBelongedTo(clientOrganizations)
    } catch (error) {
        console.error("Error fetching client organizations: ", error);
    }
  };

  // Main function to fetch plan details
  const fetchMembershipDetails = async () => {
    if (!auth.currentUser || !currentClientOrganization) {
        console.error("No current user / current clientOrg found.");
        return;
    }
    try {
        const membershipDetails = await fetchMembershipDetailsFromFirestoreForOrganization(currentClientOrganization.id);
        console.log("membershipDetails: ", membershipDetails);
        setMembershipDetails(membershipDetails)
    } catch (error) {
        console.error("Error fetching membership details: ", error);
    }
  };
  
    const renderView = () => {
      if (isMobileDevice()) {
        return <MobileWelcome />;
      }

      switch (currentView) {
        case 'awardsAdvancedSearch':
          return <AwardsAdvancedSearch 
            setSelectedOrganizationEin={setSelectedOrganizationEin}
          />;

        case 'accountSettings':
          return <AccountSettings                
            currentClientOrganization={currentClientOrganization}
            membershipDetails={membershipDetails}
            assistantId={assistantId}
            assistantVectorStoreId={assistantVectorStoreId}
            assistantFiles={assistantFiles}
          />;
        case 'organizationProfilePage':
          return <OrganizationProfilePage
            selectedOrganizationEin={selectedOrganizationEin}
            setShowLoadingModal={setShowLoadingModal}
            setShowSaveToTrackerModal={setShowSaveToTrackerModal}
          />;

        case 'rfpProfilePage':
          return <RfpProfilePage
            // selectedOpportunity={selectedOpportunity}
            membershipDetails={membershipDetails}
            setSelectedOrganizationEin={setSelectedOrganizationEin}
            // setSelectedOpportunity={setSelectedOpportunity}
            setCurrentView={setCurrentView}
            // selectedOpportunityId={selectedOpportunityId}
            selectedGrantmakingProgramId={selectedGrantmakingProgramId}
            currentProgram={currentProgram}
            setShowSaveToTrackerModal={setShowSaveToTrackerModal}       
            setSelectedGrantmakingProgramId={setSelectedGrantmakingProgramId}
            setSelectedGrantmakingProgram={setSelectedGrantmakingProgram}
            selectedGrantmakingProgram={selectedGrantmakingProgram}
          />;
        case 'rfpsSearch':
          return <RfpsSearch 
            currentProgram={currentProgram}  
            membershipDetails={membershipDetails}
            // setSelectedOpportunityId={setSelectedOpportunityId}          
            setSelectedGrantmakingProgramId={setSelectedGrantmakingProgramId}
            // setSelectedGrantmakingProgram={setSelectedGrantmakingProgram}
            // selectedGrantmakingProgram={selectedGrantmakingProgram}
            />;

        case 'grantDocumentEditor':
          return <GrantDocumentEditor
            currentGrantDocumentId={currentGrantDocumentId}
            setShowGrantDocumentVersionHistoryModal={setShowGrantDocumentVersionHistoryModal}
            setCurrentView={setCurrentView}
            setShowAiComponentEditorModal={setShowAiComponentEditorModal}
            setHighlightedTextForEditing={setHighlightedTextForEditing}
          />
        
        case 'selectedProposalPage':
          return <SelectedProposalPage 
            currentProposalId={currentProposalId}
            refreshGrantProposals={refreshGrantProposals}
            setCurrentProposalId={setCurrentProposalId}
            setCurrentView={setCurrentView}
            setShowLoadingModal={setShowLoadingModal}
            setShowManageProposalModal={setShowManageProposalModal}
            setShowMfdComponentSelectorModal={setShowMfdComponentSelectorModal}
            currentProposal={currentProposal}
            setCurrentProposal={setCurrentProposal}
            setShowKnowledgeBaseModal={setShowKnowledgeBaseModal}
            assistantFiles={assistantFiles}
            proposalDocuments={proposalDocuments}
            setCurrentGrantDocumentId={setCurrentGrantDocumentId}
            refreshProposalDocuments={refreshProposalDocuments}
          />;
        case 'proposalsDashboard':
          return <ProposalsDashboard 
            authUser={authUser}
            setCurrentProposalId={setCurrentProposalId}
            currentClientOrganization={currentClientOrganization}
            grantProposals={grantProposals}
            refreshGrantProposals={refreshGrantProposals}
            setShowAddMoreGrantsModal={setShowAddMoreGrantsModal}
          />;
      }
  };

    return (
      <div className="flex" style={{ height: '100vh', width: '100vw', overflow: 'hidden' }}>
        
        {/* Modals */}
        <div>          
          {/* {true && (
            <UserSimulator 
              fetchClientOrganizationsBelongedTo={fetchClientOrganizationsBelongedTo}   
              setCurrentProgram={setCurrentProgram}
              fetchProgramsForOrganization={fetchProgramsForOrganization}              
            />
          )} */}

          {showLoadingModal && (
            <LoadingModal              
            />
          )}

          {showPaywallModal && (
            <PaywallComponent
              onClose={() => setShowPaywallModal(false)}           
            />
          )}

          {showAddMoreGrantsModal && (
            <AddMoreGrantsModal
              setCurrentView={setCurrentView}
              setShowAddMoreGrantsModal={setShowAddMoreGrantsModal}              
              setShowSaveToTrackerModal={setShowSaveToTrackerModal}
            ></AddMoreGrantsModal>
          )}


          {showCreateProgramModal && (
            <CreateProgramModal         
              currentClientOrganization={currentClientOrganization} 
              existingProgram={programToModify}
              fetchProgramsForOrganization={fetchProgramsForOrganization}
              setCurrentProgram={setCurrentProgram}
              setShowCreateProgramModal={setShowCreateProgramModal}                            
            />
          )}


          {showClientOrganizationsPickerModal && (
            <ClientOrganizationsPickerModal         
              clientOrganizationsBelongedTo={clientOrganizationsBelongedTo}
              fetchClientOrganizationsBelongedTo={fetchClientOrganizationsBelongedTo}
              setCurrentClientOrganization={setCurrentClientOrganization}
              setShowClientOrganizationsPickerModal={setShowClientOrganizationsPickerModal}
              membershipDetails={membershipDetails}
              setShowPaywallModal={setShowPaywallModal}
            />
          )}

          {showKnowledgeBaseModal && (
            <KnowledgeBaseModal
              setShowKnowledgeBaseModal={setShowKnowledgeBaseModal}
              assistantVectorStoreId={assistantVectorStoreId}
              assistantFiles={assistantFiles}
              setAssistantFiles={setAssistantFiles}
              setShowLoadingModal={setShowLoadingModal}
              fetchAssistantFiles={fetchAssistantFiles}
              assistantId={assistantId}
              currentClientOrganization={currentClientOrganization}
              setAssistantId={setAssistantId}
            />
          )}

          {showAiComponentEditorModal && (
            <AiComponentEditorModal
              setShowAiComponentEditorModal={setShowAiComponentEditorModal}
              highlightedTextForEditing={highlightedTextForEditing}
              setShowLoadingModal={setShowLoadingModal}
            />
          )}
          
          {showManageProposalModal && (
            <ManageProposalModal
              currentProposal={currentProposal}  
              fetchGrantProposalByIdFromFirestore={fetchGrantProposalByIdFromFirestore}
              setCurrentProposalId={setCurrentProposalId}
              setShowManageProposalModal={setShowManageProposalModal}   
              setCurrentProposal={setCurrentProposal}
            />
          )}

          {showSaveToTrackerModal && (
            <SaveToTrackerModal              
              authUser={authUser}
              currentClientOrganization={currentClientOrganization}  
              setShowSaveToTrackerModal={setShowSaveToTrackerModal}
              setCurrentProposalId={setCurrentProposalId}
            />
          )}

          {showGrantDocumentVersionHistoryModal && (
            <GrantDocumentVersionHistoryModal
              grantDocumentId={currentGrantDocumentId}
              setShowVersionHistoryModal={setShowGrantDocumentVersionHistoryModal}
            />
          )}

          {showMfdComponentSelectorModal && (
            <MfdComponentSelectorModal
              setShowMfdComponentSelectorModal={setShowMfdComponentSelectorModal}              
              currentProgram={currentProgram}
              currentProposalId={currentProposalId}        
              setShowLoadingModal={setShowLoadingModal}
              assistantId={assistantId}
              assistantVectorStoreId={assistantVectorStoreId}
              assistantFiles={assistantFiles}
              currentClientOrganization={currentClientOrganization}
              refreshProposalDocuments={refreshProposalDocuments}
              setCurrentGrantDocumentId={setCurrentGrantDocumentId}
              setCurrentView={setCurrentView}
            />
          )}

        </div>           
        <SideNavBar 
            currentProgram={currentProgram}
            currentView={currentView}
            currentClientOrganization={currentClientOrganization}
            membershipDetails={membershipDetails}
            organizationPrograms={organizationPrograms}
            setCurrentProgram={setCurrentProgram}
            setCurrentView={setCurrentView}                         
            setProgramToModify={setProgramToModify}
            setShowClientOrganizationsPickerModal={setShowClientOrganizationsPickerModal}          
            setShowCreateProgramModal={setShowCreateProgramModal}
            setShowPaywallModal={setShowPaywallModal}
            setShowKnowledgeBaseModal={setShowKnowledgeBaseModal}
        />
        <div style={{ flex: 1, overflowY: 'auto' }}>
          {authUser ? (
            showOnboardingFlow ? (
              <OnboardingFlow 
                fetchClientOrganizationsBelongedTo={fetchClientOrganizationsBelongedTo}
                fetchProgramsForOrganization={fetchProgramsForOrganization}                             
                setShowLoadingModal={setShowLoadingModal}
              />
            ) : (
              <div>
                {renderView()}
              </div>
            )
          ) : (
            <LoggedOutWelcome></LoggedOutWelcome>
          )}
        </div>
      </div>
    );
}

export default Home;