import React, { useState } from 'react';
import { createGrantProposalInFirestore } from '../../utilityFunctions/firestoreUtilities'

const SaveToTrackerModal = ({    
    authUser,
    currentClientOrganization,    
    setShowSaveToTrackerModal,            
    setCurrentProposalId,
}) => {

    const [proposalName, setProposalName] = useState('');
    const [proposalAmount, setProposalAmount] = useState('');
    const [proposalDeadline, setProposalDeadline] = useState('');
    const [proposalNotes, setProposalNotes] = useState('');

    const handleAddButtonClicked = async () => {
        const proposalId = await createGrantProposalInFirestore(authUser, proposalName, currentClientOrganization.id, proposalDeadline, proposalAmount, proposalNotes);
        console.log('proposalId', proposalId);
        setCurrentProposalId(proposalId);
        setShowSaveToTrackerModal(false);
    }
    
    return (
        <div className="bg-gray-900 bg-opacity-50" 
             style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: '9999', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
             onClick={() => setShowSaveToTrackerModal(false)}
        >
            
            {/* Modal */}
            <div className='text-left bg-white rounded-lg p-16' 
                 style={{ width: '70vw', height: '70vh', overflowY: 'auto' }}
                 onClick={(e) => e.stopPropagation()}>
                
                {/* Header */}                                
                <div className="flex w-full text-center text-gray-500">
                    <p className='text-3xl font-bold'>Save to Tracker</p>                                        
                </div>
                
                {/* Body */}
                <div className="">
                    <div className='mt-6'>
                        <p className='font-bold text-gray-500 mb-1'>NAME<span className="text-red-500"> *</span></p>
                        <input id="proposal-name" type="text" required
                            className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full" 
                            value={proposalName} onChange={(e) => setProposalName(e.target.value)} />
                    </div>                    
                    
                    <div className='mt-6'>
                        <p className='font-bold text-gray-500 mb-1'>AMOUNT</p>
                        <input id="proposal-amount" type="number" 
                            className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full" 
                            value={proposalAmount} onChange={(e) => setProposalAmount(e.target.value)} />
                    </div>
                    
                    <div className='mt-6'>
                        <p className='font-bold text-gray-500 mb-1'>DEADLINE</p>
                        <input type="date" value={proposalDeadline} onChange={(e) => setProposalDeadline(e.target.value)} 
                            className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full" />
                    </div>

                    <div className='mt-6'>
                        <p className='font-bold text-gray-500 mb-1'>NOTES</p>
                        <input id="proposal-notes" type="text" 
                            className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full" 
                            value={proposalNotes} onChange={(e) => setProposalNotes(e.target.value)} />
                    </div>

                    <hr className='my-6' />

                    <div className="mt-4 space-x-4">
                        <button onClick={handleAddButtonClicked} className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded">Save</button>
                        <button onClick={() => setShowSaveToTrackerModal(false)} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">Cancel</button>                    
                    </div>                
                    
                </div>
            </div>
        </div>
    );
};

export default SaveToTrackerModal;
