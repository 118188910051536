import React, { useState, useEffect } from 'react';
import { generateFirstDraftDocumentWithAssistant } from '../../utilityFunctions/openAiUtilities';
import { createGrantDocumentInFirestore } from '../../utilityFunctions/firestoreUtilities';

const MfdComponentSelectorModal = ({
    setShowMfdComponentSelectorModal,
    currentProgram,
    currentProposalId,
    setShowLoadingModal,
    assistantId,
    assistantVectorStoreId,
    assistantFiles,
    currentClientOrganization,
    refreshProposalDocuments,
    setCurrentGrantDocumentId,
    setCurrentView
}) => {

    // State variables
    const [instructions, setInstructions] = useState('');
    const [funderPersonalization, setFunderPersonalization] = useState('');
    const [referenceFileId, setReferenceFileId] = useState('');
    const [selectedOption, setSelectedOption] = useState('');

    const defaultInstructions = {
        "Cover Letter": "Write a compelling cover letter tailored to this specific nonprofit grant opportunity. Introduce the organization, highlight its mission and impact, and explain why this grant is a strong fit. Ensure the tone is professional and aligns with the grantor's priorities.",
        "LOI": "Draft a concise and persuasive Letter of Intent (LOI) that outlines the purpose of the request. Clearly state the nonprofit's mission, the project's goals, and how the grant funding will be utilized to create impact. Keep it within the typical LOI word count limits.",
        "Custom": "Provide specific instructions for this grant document. Include details about the nonprofit’s unique needs, project goals, or any requirements from the grantor’s guidelines to ensure an effective and customized response."
    };    

    const handleGenerateDocument = async () => {
        setShowLoadingModal(true);
        const result = await generateFirstDraftDocumentWithAssistant(currentProgram, selectedOption, funderPersonalization, instructions, assistantId, referenceFileId);
        const newDocumentId = await createGrantDocumentInFirestore(currentClientOrganization.id, currentProposalId, result, selectedOption);        
        await refreshProposalDocuments();
        setCurrentGrantDocumentId(newDocumentId);
        setCurrentView("grantDocumentEditor");
        setShowMfdComponentSelectorModal(false);
        setShowLoadingModal(false);
    };

    return (
        <div className="bg-gray-900 bg-opacity-50" 
             style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: '9999', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
             onClick={() => setShowMfdComponentSelectorModal(false)}
        >
            <div className='text-left bg-white rounded-lg p-16' 
                 style={{ width: '70vw', height: '70vh', overflowY: 'auto' }}
                 onClick={(e) => e.stopPropagation()}>
                
                <div className="w-full text-left py-4">
                    <p className='text-3xl font-bold text-gray-500'>Generate a new document with AI</p>   
                    <p className='text-xs italic text-gray-400'>Assistant ID: {assistantId ? assistantId : 'N/A'}, Assistant Vector Store ID: {assistantVectorStoreId ? assistantVectorStoreId : 'N/A'}</p>

                </div>
                
                <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700">Choose Document Type <span className="text-red-500">*</span></label>
                    <select className="cursor-pointer p-4 rounded-lg outline outline-gray-300 w-full" 
                            onChange={(e) => {
                                const selectedValue = e.target.value;
                                console.log(selectedValue);
                                setInstructions(defaultInstructions[selectedValue] || '');
                                setSelectedOption(selectedValue);
                            }} 
                            defaultValue="" required>
                        <option value="" disabled></option>
                        <option value="Cover Letter">✉️ Cover Letter</option>
                        <option value="LOI">📝 Letter of Intent (LOI)</option>
                        <option value="Custom">✏️ Custom</option>
                    </select>
                </div>

                <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700 w-full">Instructions <span className="text-red-500">*</span></label>
                    <p className="text-sm text-gray-500 italic mb-2">Add instructions about the document you want Daisy to write.</p>
                    <textarea className="cursor-pointer p-4 rounded-lg outline outline-gray-300 w-full" 
                              rows="3" 
                              value={instructions} 
                              onChange={(e) => setInstructions(e.target.value)} required></textarea>
                </div>

                <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700 w-full">Funder / Opportunity Personalization <span className="text-red-500">*</span></label>
                    <p className="text-sm text-gray-500 italic mb-2">Add details about the funder or opportunity to help Daisy write a more personalized document.</p>
                    <textarea 
                        type="text" 
                        className="cursor-pointer p-4 rounded-lg outline outline-gray-300 w-full" 
                        rows="3" 
                        value={funderPersonalization}
                        onChange={(e) => setFunderPersonalization(e.target.value)}
                        required 
                    />
                </div>

                <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700 w-full">Reference File <span className="text-red-500">*</span></label> 
                    <p className="text-sm text-gray-500 italic mb-2">Daisy can reference a file from your training data set when generating this new document.</p>
                    <select className="cursor-pointer p-4 rounded-lg outline outline-gray-300 w-full" 
                            defaultValue="" 
                            onChange={(e) => setReferenceFileId(e.target.value)}
                    >
                        <option value="" disabled></option>
                        {assistantFiles.map((file, index) => (
                            <option key={index} value={file.id}>📄 {file.fileName}</option>
                        ))}
                    </select>
                </div>

                <div className="mt-4">
                    <button 
                        className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded mt-6"
                        onClick={async () => {
                            // Validate required fields before submitting
                            if (!instructions || !funderPersonalization || !selectedOption || !referenceFileId) {
                                alert("Document type, instructions, funder personalization, and reference file are required.");
                                return; // Prevent submission
                            }
                            await handleGenerateDocument(); // Call the new async function
                        }}
                    >
                        Generate
                    </button>
                </div>                
            </div>
        </div>
    );
};

export default MfdComponentSelectorModal;
