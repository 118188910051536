// import { AgGridReact } from 'ag-grid-react';
// import { CustomCellRendererProps } from '@ag-grid-community/react';
import React from 'react';

export default (params) => {
    return (
        <a 
            href={`/profiles/organization/${params.data.funder_ein}`} 
            rel="noopener noreferrer" 
            style={{ color: '#4338CA', fontWeight: 'bold' }}
        >
            {params.value.toLocaleUpperCase()}
        </a>
    );
};