import React, { useEffect, useState } from 'react';
import { fetchSingleProgramFitScoreFromSupabase, fetchSingleGrantmakingProgramFromSupabase } from '../../utilityFunctions/supabaseUtilities';

const RfpProfilePage = ({ 
    membershipDetails,
    currentProgram,
    setCurrentView,
    setSelectedOrganizationEin,
    setShowSaveToTrackerModal,
    setSelectedGrantmakingProgramId,
    selectedGrantmakingProgram,
    setSelectedGrantmakingProgram,
    selectedGrantmakingProgramId
}) => {

    const [selectedGrantmakingProgramScore, setSelectedGrantmakingProgramScore] = useState(null);
    
    // Fetch the selected opportunity and corresponding score from Supabase
    useEffect(() => {
        const fetchSingleRFP = async () => {

            const programData = await fetchSingleGrantmakingProgramFromSupabase(selectedGrantmakingProgramId);

            setSelectedGrantmakingProgram(programData[0]);

            const scoreData = await fetchSingleProgramFitScoreFromSupabase(selectedGrantmakingProgramId, currentProgram.id);

            setSelectedGrantmakingProgramScore(scoreData[0]);
        };
        fetchSingleRFP();
    }, [selectedGrantmakingProgramId]);
    
    // Return to the RFP search page
    const returnToRfpSearch = () => {
        setSelectedGrantmakingProgram(null);
        setSelectedGrantmakingProgramId(null);
        setCurrentView('rfpsSearch');
        window.history.pushState(null, '', '/grantmakingprograms');
    };


    return (
        selectedGrantmakingProgram ? (
            <div className='p-12 text-left'>
                {/* <div className=""> */}
                <div className='ml-6'>
                    <button className='block text-sm text-gray-500 text-left mb-4' onClick={returnToRfpSearch}>Return to RFP Search</button>                    
                </div>
                {/* </div> */}
                <div className='text-sm'>
                    <div className='flex flex-row justify-between'>
                        <div className="ml-6 mt-2 text-3xl font-bold">{selectedGrantmakingProgram.name}</div>
                        <div className='flex space-x-2'>
                            {/* <button className='bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded' onClick={() => {
                                navigator.clipboard.writeText(window.location.href);
                                alert('URL copied to clipboard');
                            }}>Copy URL</button> */}
                            <button className='bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded' onClick={() => setShowSaveToTrackerModal(true)}>Save to Tracker</button>
                        </div>
                    </div>
                    
                    <div className="ml-6 mt-4">
                        {/* <a className='text-blue-800' href="#" onClick={handleViewFunderClicked}>View Funder</a> */}
                        <a 
                            href={`/profiles/organization/${selectedGrantmakingProgram.ein}`} 
                            rel="noopener noreferrer" 
                            // style={{ color: '#4338CA', fontWeight: 'bold' }}
                            className='text-blue-800'
                        >
                            View Funder
                        </a>
                        <span className='text-gray-500'> | </span>
                        <a className='text-blue-800' href={selectedGrantmakingProgram.website_url} target="_blank" rel="noopener noreferrer">Visit Website</a>
                    </div>
                    
                    {/* Score Div */}
                    {membershipDetails ? (
                        <>
                            {
                                selectedGrantmakingProgramScore ? (
                                    selectedGrantmakingProgramScore.fit_category.includes('Bad') ? (
                                        <div className="m-6 bg-red-100 p-6 rounded-lg">
                                        <p className='font-bold text-lg'>{selectedGrantmakingProgramScore.fit_category}</p>
                                        <p>{selectedGrantmakingProgramScore.fit_score}</p>
                                    </div>  
                                    ) : (
                                        <div className="m-6 bg-green-100 p-6 rounded-lg">
                                            <p className='font-bold text-lg'>{selectedGrantmakingProgramScore.fit_category}</p>
                                            <p>{selectedGrantmakingProgramScore.fit_score}</p>
                                        </div>
                                    )
                                ) : (
                                    <div className="m-6 bg-gray-100 p-6 rounded-lg">
                                        <p className='font-bold text-lg'>🕐 Loading</p>
                                        <p>Daisy is processing... New opportunities and match scores are updated approximately every week.</p>
                                    </div>
                                )
                            }
                        </>
                    ) : (
                        <div className="m-6 bg-indigo-500 p-6 rounded-lg">
                            <p className='font-bold text-lg text-white'>🔓 Match score for Premium Plan only. <a href="https://satodadj.gumroad.com/l/rlqzat" className='underline' target="_blank" rel="noopener noreferrer">Upgrade now</a>.</p>
                        </div>
                    )}

                    {/* Key Details */}
                    <hr className='my-4 mx-6' />
                    <p className='text-xl ml-6 font-bold'>Key Details</p>
                    {selectedGrantmakingProgram.grant_overview && (
                            <div className="m-6"><strong>Overview</strong><br /><span className="richTextContainer" dangerouslySetInnerHTML={{ __html: selectedGrantmakingProgram.grant_overview }} /></div>
                    )}
                    {selectedGrantmakingProgram.funder_details && (
                        <div className="m-6"><strong>Funder Details</strong><br /><span className="richTextContainer" dangerouslySetInnerHTML={{ __html: selectedGrantmakingProgram.funder_details }} /></div>
                    )}              

                    {/* Eligibility Requirements */}
                    <hr className='my-4 mx-6' />
                    <p className='text-xl ml-6 font-bold'>Eligibility Requirements</p>
                    {selectedGrantmakingProgram.eligibility_criteria && (
                        <div className="m-6"><span className="richTextContainer" dangerouslySetInnerHTML={{ __html: selectedGrantmakingProgram.eligibility_criteria }} /></div>
                    )}

                    {/* Application Information */}
                    <hr className='my-4 mx-6' />
                    <p className='text-xl ml-6 font-bold'>Application Information</p>
                    {selectedGrantmakingProgram.application_details && (
                        <div className="m-6"><span className="richTextContainer" dangerouslySetInnerHTML={{ __html: selectedGrantmakingProgram.application_details }} /></div>
                    )}

                    {/* Additional Information */}
                    <hr className='my-4 mx-6' />
                    <p className='text-xl ml-6 font-bold'>Additional Information</p>
                    {selectedGrantmakingProgram.ein && (
                        <div className="m-6"><strong>Employer Identification Number (EIN)</strong><br />{selectedGrantmakingProgram.ein}</div>
                    )}

                </div>
                <div className='flex flex-row justify-center bg-gray-100 p-6 rounded-lg'>
                    <p className='ml-6 italic text-xs text-gray-700'>
                        This RFP Profile was created using AI. Spot anything weird? <a href="https://tally.so/r/mDVa5j" className='underline' target="_blank" rel="noopener noreferrer">Report it here</a>.  
                    </p>
                </div>
                
            </div>
        ) : (
            <div className='p-24 text-center flex items-center justify-center text-gray-500'>
                No opportunity selected
            </div>
        )
    );
};

export default RfpProfilePage;
