import db, { auth } from '../config/firebaseConfig';
import { getFirestore, collection, Timestamp, addDoc, getDocs, updateDoc, getDoc, setDoc, deleteDoc, query, where, orderBy, doc, limit, arrayUnion } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { marked } from 'marked';
import { v4 as uuidv4 } from 'uuid';

export const saveAssistantToFirestore = async (myAssistant, vectorStoreId, clientOrganizationId) => {
    try {
        const docRef = await addDoc(collection(db, "assistants"), {
            assistantId: myAssistant.id,
            object: myAssistant.object,
            createdAt: myAssistant.created_at,
            name: myAssistant.name,
            description: myAssistant.description,
            model: myAssistant.model,
            instructions: myAssistant.instructions,
            toolResources: myAssistant.tool_resources,
            vectorStoreId: vectorStoreId,
            tools: myAssistant.tools,
            metadata: myAssistant.metadata,
            clientOrganizationId: clientOrganizationId,
            createdBy: auth.currentUser.uid,
        });
        console.log("Assistant saved to Firestore with ID: ", docRef.id);
        return docRef.id;
    } catch (e) {
        console.error("Error adding document: ", e);
        throw e;
    }
};

// Save thread metadata to Firestore
export const saveThreadToFirestore = async (thread, userInput, assistantId) => {
    try {
        const docRef = await addDoc(collection(db, "threads"), {
            assistant_id: assistantId,
            created_at: thread.created_at,
            id: thread.id,
            object: thread.object,
            thread_id: thread.id,
            threadName: userInput
        });
        console.log("Thread saved with ID: ", docRef.id);
    } catch (e) {
        console.error("Error adding document: ", e);
    }
};


export const fetchThreadsForAssistantFromFirestore = async (assistantId) => {
    if (assistantId === null) {
        console.log("Assistant ID is null, fetch operation aborted.");
        return [];
    }
    console.log("Running fetch for Threads");
    const querySnapshot = await getDocs(query(collection(db, "threads"), where("assistant_id", "==", assistantId), orderBy("created_at", "desc")));
    const threadOptions = [];
    querySnapshot.forEach((doc) => {
        threadOptions.push({ 
            threadId: doc.data().thread_id, 
            createdAt: doc.data().created_at, 
            threadName: doc.data().threadName 
        });
    });
    return threadOptions;
};

export const fetchAssistantDetailsFromFirestoreForOrganization = async (clientOrganizationId) => {
    try {
        const querySnapshot = await getDocs(query(collection(db, "assistants"), where("clientOrganizationId", "==", clientOrganizationId)));
        let assistantDetails = null;

        querySnapshot.forEach((doc) => {
            const data = doc.data();
            assistantDetails = {
                assistantId: data.assistantId || null,
                assistantName: data.name || null,
                assistantVsId: data.vectorStoreId || null,
            };
        });

        return assistantDetails;
    } catch (error) {
        console.error("Error fetching assistant details: ", error);
        throw error;
    }
};

export const fetchMembershipDetailsFromFirestore = async (userEmail) => {
    try {
        const querySnapshot = await getDocs(query(collection(db, "purchases"), where("email", "==", userEmail)));
        let membershipDetails = null; // Initialize membershipDetails

        querySnapshot.forEach((doc) => {
            const data = doc.data();
            console.log(data);
            membershipDetails = data; // Assign data to membershipDetails
        });

        return membershipDetails; // Return membershipDetails
    } catch (error) {
        console.error("Error fetching membership details: ", error);
        throw error;
    }
};

export const fetchClientOrganizationsFromFirestore = async (userId) => {
    try {
        const querySnapshot = await getDocs(query(collection(db, "clientOrganizations"), where("members", "array-contains", userId)));
        let organizationsBelongedTo = []; 

        querySnapshot.forEach((doc) => {
            const data = doc.data();
            organizationsBelongedTo.push({ id: doc.id, ...data }); // Append data with document ID
        });

        return organizationsBelongedTo; // Return membershipDetails
    } catch (error) {
        console.error("Error fetching organizationsBelongedTo: ", error);
        throw error;
    }
};

export const fetchClientProgramsFromFirestore = async (organizationId) => {
    console.log("Fetching client programs from Firestore")
    try {
        const querySnapshot = await getDocs(query(collection(db, "programs"), where("clientOrganizationId", "==", organizationId)));
        let clientPrograms = []; 

        querySnapshot.forEach((doc) => {
            const data = doc.data();
            clientPrograms.push({ id: doc.id, ...data }); 
        });

        console.log("Client programs fetched: ", clientPrograms)
        return clientPrograms;
    } catch (error) {
        console.error("Error fetching client programs: ", error);
        throw error;
    }
};

// New function to fetch files metadata from Firestore
export const fetchFilesMetadataFromFirestore = async (vectorStoreFiles) => {
    console.log("Fetching files metadata from Firestore")
    return await Promise.all(vectorStoreFiles.map(async (file) => {
        const q = query(collection(db, 'files'), where('openaiFileId', '==', file.id));
        const querySnapshot = await getDocs(q);

        let downloadURL = null;
        let fileName = null;
        querySnapshot.forEach(docSnapshot => {
            const fileData = docSnapshot.data();
            if (fileData.downloadURL) {
                downloadURL = fileData.downloadURL;
            }
            if (fileData.fileName) {
                fileName = fileData.fileName;
            } else {
                fileName = "File name missing; reupload.";
            }
        });
        return {
            ...file,
            fileName,
            downloadURL
        };
    }));
};

export const fetchGrantDocumentsFromFirestore = async (authUser) => {
    if (!authUser || !authUser.uid) {
        console.log("fetchGrantDocumentsFromFirestore() called, but authUser not set. Skipping fetch.");
        return [];
    }
    console.log("fetchGrantDocumentsFromFirestore() called, and authUser set. Proceeding to fetch");
    
    try {
        const q = query(collection(db, "grantDocuments"), where("userId", "==", authUser.uid), orderBy("updatedAt", "desc"));
        const querySnapshot = await getDocs(q);
        const docsArray = [];
        querySnapshot.forEach(doc => {
            docsArray.push({ id: doc.id, ...doc.data() });
        });
        return docsArray; 
    } catch (error) {
        console.error("Error fetching grant documents:", error);
        return [];
    }
};

export const fetchGrantProposalsFromFirestoreForOrganization = async (clientOrganizationId) => {
    if (!clientOrganizationId) {
        console.log("fetchGrantProposalsFromFirestoreForOrganization() called, but clientOrganizationId not set. Skipping fetch.");
        return [];
    }
    console.log("fetchGrantProposalsFromFirestoreForOrganization() called, and clientOrganizationId set. Proceeding to fetch");
    
    try {
        const q = query(collection(db, "grantProposals"), where("clientOrganizationId", "==", clientOrganizationId), orderBy("updatedAt", "desc"));
        const querySnapshot = await getDocs(q);
        const docsArray = [];
        querySnapshot.forEach(doc => {
            docsArray.push({ id: doc.id, ...doc.data() });
        });
        return docsArray; 
    } catch (error) {
        console.error("Error fetching grant proposals:", error);
        return [];
    }
};

export const fetchGrantProposalByIdFromFirestore = async (documentId) => {
    if (!documentId) {
        console.log("fetchGrantProposalByIdFromFirestore() called, but documentId not set. Skipping fetch.");
        return null;
    }
    console.log("fetchGrantProposalByIdFromFirestore() called, and documentId set. Proceeding to fetch");
    
    try {
        const docRef = doc(db, "grantProposals", documentId);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
            return { id: docSnapshot.id, ...docSnapshot.data() };
        } else {
            console.log("No grant proposal found with the given document ID.");
            return null;
        }
    } catch (error) {
        console.error("Error fetching grant proposal by ID:", error);
        return null;
    }
};

export const markFileAsDeletedInFirestore = async (fileId) => {
    try {
        // Query the Firestore document using the openaiFileId
        const q = query(collection(db, 'files'), where('openaiFileId', '==', fileId));
        const querySnapshot = await getDocs(q);

        // Update the document to mark it as deleted
        querySnapshot.forEach(async (docSnapshot) => {
            const firestoreRef = doc(db, 'files', docSnapshot.id);
            await updateDoc(firestoreRef, {
                deleted: true,
                deletedAt: new Date()
            });
            console.log("File marked as deleted in Firestore.");
        });
    } catch (error) {
        console.error("Failed to mark file as deleted in Firestore: ", error);
        throw error;
    }
};

// Function to upload file to Firebase Storage
export const uploadFileToFirebaseStorage = async (file, uniqueFileId) => {
    const storage = getStorage();
    const filePath = `files/${uniqueFileId}`;
    const storageRef = ref(storage, filePath);
    console.log("Uploading file to Firebase Storage at path:", filePath);
    const snapshot = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);
    console.log("File uploaded to Firebase Storage, download URL:", downloadURL);
    return downloadURL;
};

// Function to save file metadata to Firestore
export const saveFileMetadataToFirestore = async (uniqueFileId, fileName, downloadURL, userId) => {
    const firestore = getFirestore();
    const firestoreRef = doc(collection(firestore, 'files'), uniqueFileId);
    console.log("Saving file metadata to Firestore for ID:", uniqueFileId);
    await setDoc(firestoreRef, {
        firestoreFileId: uniqueFileId,
        fileName: fileName,
        downloadURL: downloadURL,
        uploadedAt: new Date(),
        userId: userId
    });
    console.log("File metadata saved to Firestore for ID:", uniqueFileId);
    return firestoreRef;
};

// Function to save OpenAI file ID to Firestore
export const saveOpenAiFileIdToFirestore = async (firestoreRef, openaiFileId) => {
    console.log("Saving OpenAI file ID to Firestore");
    await setDoc(firestoreRef, {
        openaiFileId: openaiFileId
    }, { merge: true });
    console.log("OpenAI file ID saved to Firestore");
};

export const createGrantDocumentInFirestore = async (clientOrganizationId, proposalId, content, selectedOption) => {
    const docRef = await addDoc(collection(db, "grantDocuments"), {
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
        clientOrganizationId: clientOrganizationId,
        proposalId: proposalId,        
        content: content,
        documentName: selectedOption,        
    });
    return docRef.id;
};

export const deleteGrantDocumentFromFirestore = async (documentId) => {
    try {                                                                
        await deleteDoc(doc(db, "grantDocuments", documentId));            
        console.log("Document successfully deleted!");
    } catch (error) {
        console.error("Error removing document: ", error);
    }
};

export const createProgramInFirestore = async (programDetails, userId, clientOrganizationId, programName) => {
    const { overview, mission, populationsServed, areasServed } = programDetails;
    const docRef = await addDoc(collection(db, "programs"), {
        createdAt: Date.now(),
        updatedAt: Date.now(),
        overview: overview ? marked(overview) : null,
        mission: mission ? marked(mission) : null,
        populationsServed: populationsServed ? marked(populationsServed) : null,
        areasServed: areasServed ? marked(areasServed) : null,
        createdBy: userId,
        clientOrganizationId: clientOrganizationId,
        programName: programName
    });
    return docRef;
};

export const updateProgramInFirestore = async (programId, programDetails, userId, clientOrganizationId) => {
    const { overview, populationsServed, areasServed, programName } = programDetails;
    const docRef = doc(db, "programs", programId); // Reference to the document to be updated

    await updateDoc(docRef, {
        updatedAt: Date.now(),
        overview: overview,
        populationsServed: populationsServed,
        areasServed: areasServed,
        lastUpdatedBy: userId,
        clientOrganizationId: clientOrganizationId,
        programName: programName
    });

    return docRef;
};

export const createClientOrganizationInFirestore = async (organizationName, userId) => {    
    const docRef = await addDoc(collection(db, "clientOrganizations"), {
        createdAt: Date.now(),
        updatedAt: Date.now(),
        name: organizationName,        
        members: [userId],
    });
    return docRef;
};

export const fetchOrganizationDetailsFromFirestore = async (userId) => {
    try {
        const querySnapshot = await getDocs(query(collection(db, "organizationDetails"), where("userId", "==", userId)));
        let organizationDetails = null; 

        querySnapshot.forEach((doc) => {
            const data = doc.data();
            console.log(data);
            organizationDetails = data; 
        });

        return organizationDetails; 
    } catch (error) {
        console.error("Error fetching org details: ", error);
        throw error;
    }
};

export const updateOrganizationDetailsInFirestore = async (userId, updatedDetails) => {
    try {
        const q = query(collection(db, 'organizationDetails'), where('userId', '==', userId));
        const querySnapshot = await getDocs(q);
        let docRef;
        querySnapshot.forEach((doc) => {
            docRef = doc.ref;
        });

        if (docRef) {
            await setDoc(docRef, updatedDetails, { merge: true });
            return { success: true, message: 'Details updated successfully' };
        } else {
            // Create a new document if no matching document is found
            await addDoc(collection(db, 'organizationDetails'), {
                userId,
                ...updatedDetails,
                createdAt: new Date(),
                updatedAt: new Date()
            });
            return { success: true, message: 'New document created successfully' };
        }
    } catch (error) {
        console.error('Error updating details: ', error);
        return { success: false, message: 'Failed to update details' };
    }
};

export const saveGrantDocumentToFirestore = async (currentDocumentId, textEditorValue, authUser) => {
    try {
        const timestamp = Date.now();
        if (currentDocumentId) {
            // Update existing document
            const docRef = doc(db, "grantDocuments", currentDocumentId);
            await updateDoc(docRef, {
                updatedAt: timestamp,
                content: textEditorValue,
            });
            console.log("Document updated successfully");

            // Save a backup copy to firestore
            await addDoc(collection(db, "grantDocumentsBackups"), {
                content: textEditorValue,
                createdAt: timestamp,
                userId: authUser.uid,
                documentId: currentDocumentId
            });
            console.log("Backup saved successfully");

        } else {
            // Add a new document, if no document exists
            await addDoc(collection(db, "grantDocuments"), {
                createdAt: timestamp,
                updatedAt: timestamp,
                content: textEditorValue,
                userId: authUser.uid,
            });
            console.log("Document saved successfully");
        }
    } catch (error) {
        console.error("Error saving document: ", error);
    }
};

export const fetchUserReferralCodeFromFirestore = async (authUser) => {
    if (!authUser || !authUser.uid) {
        console.log("authUser not set. Skipping fetch of referred users.");
        return null;
    }
    console.log("fetchReferredUsersFromFirestore() called, and authUser set. Proceeding to fetch");

    try {
        const userRef = doc(db, "users", authUser.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
            const fetchedReferralCode = userDoc.data().permanentReferralCode || null;
            console.log("Referral code fetched:", fetchedReferralCode);
            return fetchedReferralCode;
        } else {
            console.log("No referral code found for the given authUser.uid");
            return null;
        }
    } catch (error) {
        console.error("Error fetching user's referral code:", error);
        return null;
    }
};
export const updatePermanentReferralCodeForUserInFirestore = async (userId, referralCode) => {
    try {
        const userRef = doc(db, 'users', userId);
        await setDoc(userRef, {
            permanentReferralCode: referralCode
        }, { merge: true });
    } catch (error) {
        console.error("Error saving referral code: ", error);
    }
};

export const fetchReferredUsersFromFirestore = async (authUser, permanentReferralCode) => {
    if (!authUser || !authUser.uid) {
        console.log("fetchReferredUsersFromFirestore() called, but authUser not set. Skipping fetch.");
        return [];
    }
    console.log("fetchReferredUsersFromFirestore() called, and authUser set. Proceeding to fetch");
    
    try {
        const q = query(collection(db, "users"), where("utilizedReferralCode", "==", permanentReferralCode))
        const querySnapshot = await getDocs(q);
        const usersArray = [];
        querySnapshot.forEach(doc => {
            usersArray.push({ id: doc.id, ...doc.data() });
        });
        console.log("All referred users fetched: ", usersArray)
        return usersArray; 
    } catch (error) {
        console.error("Error fetching referred users:", error);
        return [];
    }
};

export const fetchGrantAwardsFromFirestore = async (formData) => {
    const firestore = getFirestore();
    const grantAwardsRef = collection(firestore, '990Awards');
    const maxResults = 10000; 
    let results = [];

    const buildQuery = () => {
        let q = query(grantAwardsRef);
        if (formData.grantSizeMin) {
            q = query(q, where('AwardDetails.CashGrantAmt', '>=', parseFloat(formData.grantSizeMin)));
        }
        if (formData.grantSizeMax) {
            q = query(q, where('AwardDetails.CashGrantAmt', '<=', Number(formData.grantSizeMax)));
        }
        if (formData.funderZip) {
            q = query(q, where('FilerObject.FilerGeneralDetails.Address.Zip', '==', formData.funderZip));
        }
        if (formData.funderCity) {
            q = query(q, where('FilerObject.FilerGeneralDetails.Address.City', '==', formData.funderCity));
        }
        if (formData.funderState) {
            q = query(q, where('FilerObject.FilerGeneralDetails.Address.State', '==', formData.funderState));
        }
        if (formData.recipientZip) {
            q = query(q, where('RecipientDetails.ZIP', '==', formData.recipientZip));
        }
        if (formData.recipientCity) {
            q = query(q, where('RecipientDetails.City', '==', formData.recipientCity));
        }
        if (formData.recipientState) {
            q = query(q, where('RecipientDetails.State', '==', formData.recipientState));
        }
        if (formData.taxYr) {
            q = query(q, where('FilingDetails.TaxYr', '==', formData.taxYr));
        }

        return query(q, limit(maxResults));
    };

    try {
        const q = buildQuery();
        console.log("Attempting to fetch awards with query:", q);
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach(doc => {
            const data = doc.data();
            results.push({ id: doc.id, ...data });
        });

        return results;
    } catch (error) {
        if (error.code === 'failed-precondition') {
            alert("Error fetching grant awards. Please contact support.");
            console.error("Error fetching grant awards:", error);
        } else {
            console.error("Error fetching grant awards:", error);
        }
        return [];
    }
};

export const createGrantProposalComponentInFirestore = async (currentProposalId, proposalRequirement, responseText = null) => {
    const newComponent = {
        proposalId: currentProposalId,
        proposalRequirement, 
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
        ...(responseText && { responseText }) 
    };
    try {        
        const docRef = await addDoc(collection(db, "grantProposalComponents"), newComponent);        
        console.log("Component added to proposal successfully. ID: ", docRef.id);        
    } catch (error) {
        console.error("Error adding component to proposal: ", error);
    }
};


export const deleteProgramFromFirestore = async (programId) => {        
    try {                                                                
        await deleteDoc(doc(db, "programs", programId));            
        console.log("Program successfully deleted!");
    } catch (error) {
        console.error("Error removing document: ", error);
    }

}

export const fetchMemberEmails = async (currentClientOrganization) => {
    const db = getFirestore();
    const emails = [];
    for (const memberId of currentClientOrganization.members) {
        const docRef = doc(collection(db, 'users'), memberId);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
            emails.push(docSnapshot.data().email);
        }
    }
    console.log("Member Emails:", emails);
    return emails;
};


export const handleAddTeammateToFirestore = async (currentClientOrganization) => {
    const email = prompt("Enter teammate's email:");
    if (email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('email', '==', email));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            const userId = querySnapshot.docs[0].id;
            const orgRef = doc(db, 'clientOrganizations', currentClientOrganization.id);
            await updateDoc(orgRef, {
                members: arrayUnion(userId)
            });
            alert('Teammate added successfully!');
        } else {
            alert('No user found with that email.');
        }
    } else {
        alert('Please enter a valid email.');
    }
};

export const handleAddLicenseKeyClicked = async (clientOrganizationId) => {
    const licenseKey = prompt("Please enter your license key:");
    if (licenseKey) {
        console.log("Checking for purchase with sale ID: ", licenseKey)
        const purchaseRef = collection(db, "purchases"); // Reference to the collection
        const q = query(purchaseRef, where('saleId', '==', licenseKey), where('claimed', '!=', true)); // Query the collection
        const querySnapshot = await getDocs(q); // Get the documents matching the query

        if (!querySnapshot.empty) {
            const purchaseDoc = querySnapshot.docs[0]; // Get the first matching document
            await updateDoc(purchaseDoc.ref, { claimed: true, clientOrganizationId: clientOrganizationId}); // Update the document
            alert("License key claimed successfully! Refreshing page...");
            window.location.reload();
        } else {
            alert("Invalid license key. Please try again.");
        }
    }
};


export const fetchMembershipDetailsFromFirestoreForOrganization = async (clientOrganizationId) => {
    try {
        const querySnapshot = await getDocs(query(collection(db, "purchases"), where("clientOrganizationId", "==", clientOrganizationId)));
        let membershipDetails = null; // Initialize membershipDetails

        querySnapshot.forEach((doc) => {
            const data = doc.data();
            // console.log(data);
            membershipDetails = data; // Assign data to membershipDetails
        });

        console.log("MEMBER: ", membershipDetails)

        return membershipDetails; // Return membershipDetails
    } catch (error) {
        console.error("Error fetching membership details: ", error);
        throw error;
    }
};

export const createGoalInFirestore = async (goalAmount, organizationId) => {
    try {
        const docRef = await addDoc(collection(db, "goals"), {
            goalAmount: Number(goalAmount), // Ensure goalAmount is saved as a number
            organizationId: organizationId
        });
        console.log("Goal created with ID: ", docRef.id);
    } catch (error) {
        console.error("Error adding goal to Firestore: ", error);
    }
};

export const updateNewsletterSubscription = async ({ userId, email, clientOrganizationId, currentProgramId, subscribed }) => {
    try {
        const subscriptionRef = doc(db, 'newsletterSubscriptions', `${currentProgramId}_${userId}`);

        await setDoc(subscriptionRef, {
            email,
            clientOrganizationId,
            currentProgramId,
            // assistantId,
            userId,
            subscribed,
            updatedAt: new Date()
        }, { merge: true });

        console.log(`Newsletter subscription updated for user ID: ${userId}`);
    } catch (error) {
        console.error("Error updating newsletter subscription: ", error);
    }
};
export const checkSubscription = async (authUser, currentProgramId) => {
    try {
        const q = query(collection(db, 'newsletterSubscriptions'), 
                        where('currentProgramId', '==', currentProgramId), 
                        where('userId', '==', authUser.uid));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            const data = querySnapshot.docs[0].data();
            console.log("Subscription data:", data);
            return data.subscribed === true;
        } else {
            console.log("No subscription found for user.");
            return false;
        }
    } catch (error) {
        console.error("Error checking subscription status: ", error);
    }
};


export const fetchProposalComponentsFromFirestore = async (currentProposal) => {        
    
    try {
        console.log("Fetching grant proposal components for proposal ", currentProposal.id)        
        const q = query(collection(db, "grantProposalComponents"), where("proposalId", "==", currentProposal.id));
        const querySnapshot = await getDocs(q);
        const fetchedProposalComponents = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            data.id = doc.id; // Add the document ID to the data
            // console.log(data);
            fetchedProposalComponents.push(data);
        });            
        
        fetchedProposalComponents.sort((a, b) => a.createdAt.seconds - b.createdAt.seconds); // Sort components by createdAt timestamp
        
        console.log("Fetched Proposal Components from utils file: ", fetchedProposalComponents);
        
        return fetchedProposalComponents;
    } catch (error) {
        console.error("Error fetching proposal components: ", error);
    }
};

export const deleteGrantProposalComponentInFirestore = async (componentId) => {
    try {
        console.log("Deleting grant proposal component in Firestore")
        const docRef = doc(db, "grantProposalComponents", componentId);
        await deleteDoc(docRef);
        console.log("Component deleted successfully. ID: ", componentId);
    } catch (error) {
        console.error("Error deleting component: ", error);
    }
};

export const updateComponentTextInFirestore = async (componentId, newResponseText) => {
    try {
        console.log("Updating component text in Firestore")
        const componentDocRef = doc(db, 'grantProposalComponents', componentId);
        await updateDoc(componentDocRef, { responseText: newResponseText, updatedAt: new Date() });
        // setUnsavedChangesPresent(false); // Reset the unsaved changes indicator
    } catch (error) {
        console.error('Error updating component text: ', error);
        alert('Failed to update component text');
    }
};

export const createComponentTextBackupInFirestore = async (componentId, newResponseText) => {
    try {
        const backupDocRef = doc(db, 'grantProposalComponentBackups', uuidv4());
        await setDoc(backupDocRef, { 
            componentId: componentId, 
            responseText: newResponseText, 
            createdAt: Timestamp.now(),
        });
        console.log('Backup document created:', backupDocRef.id);
    } catch (error) {
        console.error('Error creating backup document: ', error);
    }
};

export const updateGrantProposalInFirestore = async (proposalId, name, status, notes, amount, deadline) => {
    console.log("Updating proposal in Firestore with deadline: ", deadline)
    const deadlineTimestamp = deadline ? Timestamp.fromDate(new Date(deadline)) : null; // Convert to Firestore Timestamp or set to null
    console.log("Deadline Timestamp: ", deadlineTimestamp)
    try {
        const proposalRef = doc(db, 'grantProposals', proposalId);
        await updateDoc(proposalRef, {
            name: name,
            status: status,
            notes: notes,
            amount: amount,
            deadline: deadlineTimestamp 
        });
        console.log('Proposal updated successfully');
    } catch (error) {
        console.error('Error updating proposal: ', error);
        throw error; // Rethrow the error for handling in the calling function
    }
};

// Main function to handle when user creates a document
export const createGrantProposalInFirestore = async (authUser, grantName, clientOrganizationId, deadline = null, amount = null, notes = null) => {
    console.log("createGrantProposal");                
    const docRef = await addDoc(collection(db, "grantProposals"), {
        name: grantName,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
        userId: authUser.uid,
        clientOrganizationId: clientOrganizationId,
        status: "In Progress",
        ...(deadline && { deadline: Timestamp.fromDate(new Date(deadline)) }), // Include deadline if provided
        ...(amount && { amount }),     // Include amount if provided
        ...(notes && { notes })        // Include notes if provided
    });

    return docRef.id; // Return the ID of the created document
};

export const fetchGrantDocumentsFromFirestoreForProposal = async (proposalId) => {
    
    try {
        const q = query(collection(db, "grantDocuments"), where("proposalId", "==", proposalId), orderBy("updatedAt", "desc"));
        const querySnapshot = await getDocs(q);
        const docsArray = [];
        querySnapshot.forEach(doc => {
            docsArray.push({ id: doc.id, ...doc.data() });
        });
        return docsArray; 
    } catch (error) {
        console.error("Error fetching grant documents:", error);
        return [];
    }
};

export const fetchSingleGrantDocumentFromFirestore = async (grantDocumentId) => {
    
    try {
        console.log("Fetching single grant document from Firestore", grantDocumentId);
        const docRef = doc(db, "grantDocuments", grantDocumentId); // Use documentId as the reference
        const docSnapshot = await getDoc(docRef); // Fetch the document snapshot

        if (docSnapshot.exists()) {
            return { id: docSnapshot.id, ...docSnapshot.data() }; // Return the document data
        } else {
            console.log("No such document!");
            return null; // Return null if the document does not exist
        }
    } catch (error) {
        console.error("Error fetching grant documents:", error);
        return null; // Return null in case of an error
    }
};

export const updateGrantDocumentInFirestore = async (grantDocumentId, content) => {
    const docRef = doc(db, 'grantDocuments', grantDocumentId); // Adjust the collection name as needed
    try {
        await updateDoc(docRef, {
            content: content,
            updatedAt: Timestamp.now() // Update the content field
        });
        console.log("Document successfully updated!");
    } catch (error) {
        console.error("Error updating document: ", error);
    }
};

export const renameGrantDocumentInFirestore = async (grantDocumentId, newName) => {
    const docRef = doc(db, 'grantDocuments', grantDocumentId);
    await updateDoc(docRef, { documentName: newName, updatedAt: Timestamp.now() });
    console.log("Document successfully renamed!");
};

export const createGrantDocumentBackupInFirestore = async (grantDocumentId, content) => {
    const backupDocRef = doc(db, 'grantDocumentBackups', uuidv4());
    try {
        await setDoc(backupDocRef, {
            grantDocumentId: grantDocumentId,
            content: content,
            createdAt: Timestamp.now(),
            updatedAt: Timestamp.now() // Update the content field
        });
        console.log("Document successfully updated!");
    } catch (error) {
        console.error("Error updating document: ", error);
    }
};