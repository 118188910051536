import React, { useContext, useEffect } from 'react';
import { createVectorStore, createAssistantInOpenAi, deleteFileFromOpenAiVectorStore, uploadFileToOpenAI, addFileToAssistantVectorStore } from '../../utilityFunctions/openAiUtilities';
import { markFileAsDeletedInFirestore, uploadFileToFirebaseStorage, saveOpenAiFileIdToFirestore, saveFileMetadataToFirestore, saveAssistantToFirestore } from '../../utilityFunctions/firestoreUtilities';
import { generateUniqueFileId } from '../../utilityFunctions/uxUtilities';
import { AuthContext } from '../../AuthContext';

const KnowledgeBaseModal = ({
    setShowKnowledgeBaseModal,
    assistantVectorStoreId,
    assistantFiles,
    setAssistantFiles,
    setShowLoadingModal,
    fetchAssistantFiles,
    assistantId,
    setAssistantId,
    currentClientOrganization
}) => {
    
    // Upon modal load, fetch the assistant files from the vector store
    useEffect(() => {
        handleFileRetrieval();
    }, []);

    async function handleFileRetrieval() {
        setShowLoadingModal(true);
        await fetchAssistantFiles(assistantVectorStoreId);
        setShowLoadingModal(false);
    }

    const { authUser } = useContext(AuthContext);

    // Delete an assistant file from the vector store and firestore
    async function deleteAssistantFile(fileId) {
        if (window.confirm("Are you sure you want to permanently delete this file?")) {
            try {
                await deleteFileFromOpenAiVectorStore(assistantVectorStoreId, fileId);
                await markFileAsDeletedInFirestore(fileId);
                setAssistantFiles(prevFiles => prevFiles.filter(file => file.id !== fileId));
            } catch (error) {
                console.error("Failed to delete assistant file: ", error);
            }
        }
    }

    
    // Create a vector store and assistant in OpenAI and Firestore
    const createVectorStoreAndAssistant = async () => {
        setShowLoadingModal(true)
        const createdVectorStore = await createVectorStore();
        const createdAssistant = await createAssistantInOpenAi(currentClientOrganization.name, createdVectorStore.id);        
        await saveAssistantToFirestore(createdAssistant, createdVectorStore.id, currentClientOrganization.id);
        setAssistantId(createdAssistant.id);        
        setShowLoadingModal(false)
    }

    // Upload a user file to Firestore and OpenAI, then add the file to the assistant vector store
    const handleUserFileUpload = async (file) => { 
        if (!file) {
            alert("No file selected for upload.");
            console.error("No file selected for upload.");
            return;
        }

        // Check file size (2 MB = 2 * 1024 * 1024 bytes)
        if (file.size > 2 * 1024 * 1024) {
            alert("File size exceeds 2 MB. Please select a smaller file.");
            console.error("File size exceeds 2 MB.");
            return;
        }
        
        try {
            console.log("handleUserFileUpload proceeding, file detected")
            setShowLoadingModal(true)

            // Set a timeout for the upload process
            const timeout = new Promise((_, reject) => 
                setTimeout(() => reject(new Error("File upload timed out. Please try again.")), 30000) // 30 seconds timeout
            );

            const uniqueFileId = generateUniqueFileId();
            const downloadURL = await Promise.race([uploadFileToFirebaseStorage(file, uniqueFileId), timeout]);
            const firestoreRef = await saveFileMetadataToFirestore(uniqueFileId, file.name, downloadURL, authUser.uid);
            const openaiFileId = await Promise.race([uploadFileToOpenAI(file), timeout]);
            await saveOpenAiFileIdToFirestore(firestoreRef, openaiFileId);
            await addFileToAssistantVectorStore(assistantVectorStoreId, openaiFileId);
    
            fetchAssistantFiles(assistantVectorStoreId);
            setShowLoadingModal(false)
        } catch (error) {
            setShowLoadingModal(false); // Ensure loading modal is hidden
            alert(`Error uploading file: ${error.message}`); // Convey error to user
            console.error("Error uploading file:", error);
        }
    };  

    return (
        <div className="modal-overlay" 
             style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 9999 }} 
             onClick={() => setShowKnowledgeBaseModal(false)}
        >
            <div className="modal" 
                 style={{ cursor: 'default', width: '80vw', height: '80vh', backgroundColor: 'white', padding: '20px', borderRadius: '10px' }} 
                 onClick={(e) => e.stopPropagation()}
            >

                {assistantId && (
                    <div className="flex justify-end items-center space-x-4">
                        <input 
                            type="file" 
                            id="fileInput" 
                            accept=".doc, .docx, .md, .pdf, .txt"
                            style={{ display: 'none' }} 
                            onChange={async (event) => {
                                const file = event.target.files[0];
                                if (file) {
                                    await handleUserFileUpload(file); 
                                }
                            }} 
                        />
                        <button 
                            onClick={() => document.getElementById('fileInput').click()} 
                            className="text-white bg-indigo-500 hover:bg-blue-700 text-sm font-bold py-2 px-4 rounded"
                        >
                            Upload
                        </button>
                        <button onClick={() => setShowKnowledgeBaseModal(false)} className="text-white bg-gray-500 hover:bg-gray-700 text-sm font-bold py-2 px-4 rounded">
                            Close
                        </button>
                    </div>
                )}
                
                {assistantId ? (
                    <>
                        <div className="flex justify-center items-center mb-2">
                            <p className='text-xl'>Daisy's Knowledge Base</p>
                        </div>
                        <p className="text-sm ml-6 mb-2">Daisy will use these documents to learn about your nonprofit.</p>
                        <p className="text-xs ml-6 mb-12 text-gray-700 italic">Files must be less than 2 MB. The following file types are supported: .doc, .docx, .md, .pdf, .txt</p>
                        <table className="min-w-full text-left divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        File Name
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Created At
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {assistantFiles.map((file, index) => (
                                    <tr key={index}>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {file.downloadURL ? (
                                                <a href={file.downloadURL} target="_blank" rel="noopener noreferrer" className="text-sm text-indigo-600 hover:underline">
                                                    {file.fileName}
                                                </a>
                                            ) : (
                                                <div className="text-sm text-gray-900">{file.fileName}</div>
                                            )}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm text-gray-500">{new Date(file.created_at * 1000).toLocaleString()}</div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap  text-sm ">
                                            <button onClick={() => deleteAssistantFile(file.id)} className="text-red-300 hover:text-red-600">Delete</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </>
                ) : (
                    <div className="flex flex-col justify-center items-center h-full">
                        <p className='text-2xl font-bold text-center my-4'>Setup your AI Assistant</p>

                        <p className='text-xl text-gray-700 mb-4'>Initalize your AI Assistant to start generating personalized responses.</p>
                        <button onClick={createVectorStoreAndAssistant} className="text-white bg-green-500 hover:bg-green-700 text-sm font-bold py-2 px-4 rounded my-4">
                            Initalize Daisy
                        </button>
                    </div>
                )}
            </div>
       </div>
    );
};

export default KnowledgeBaseModal;

    