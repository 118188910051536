import React, { useEffect } from 'react';
import ProposalDetailsSection from '../OrganizerTools/ProposalDetailsSection';
import { fetchGrantProposalByIdFromFirestore } from '../../utilityFunctions/firestoreUtilities';
import ProposalDocumentsSection from './ProposalDocumentsSection';

const SelectedProposalPage = ({     
    currentProposalId,
    refreshGrantProposals,
    setCurrentProposalId,
    setCurrentView,
    setShowManageProposalModal,
    setCurrentProposal, 
    currentProposal,
    setShowKnowledgeBaseModal,
    assistantFiles,
    setShowMfdComponentSelectorModal,
    proposalDocuments,
    setCurrentGrantDocumentId,
    refreshProposalDocuments
}) => {

    // When user clicks home button, return to tracker dashboard
    const homeButtonClicked = () => {
        setCurrentProposalId(null);
        setCurrentView('proposalsDashboard');        
    }     

    // When the current proposal id changes, fetch the proposal from Firestore
    useEffect(() => {
        console.log('currentProposalId changed: ', currentProposalId);
        fetchGrantProposalByIdFromFirestore(currentProposalId).then(proposal => {
            setCurrentProposal(proposal);
        });

    }, [currentProposalId]);

    return (
        <div className="p-12 h-screen">
            <div className="">
                <div>
                    <button className='block text-sm text-gray-500 text-left mb-4' onClick={homeButtonClicked}>Return Home</button>
                    <div className="flex justify-between items-center w-full mt-6">
                        <p className="text-3xl font-bold text-left">{currentProposal?.name}</p>   
                        <button onClick={() => setShowManageProposalModal(true)} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">Edit Details</button>
                    </div>                    
                </div>
            </div>

            <ProposalDetailsSection 
                currentProposal={currentProposal}
                refreshGrantProposals={refreshGrantProposals}
                setShowManageProposalModal={setShowManageProposalModal}
                
            />

            <p className='text-lg font-bold text-left text-gray-700'>AI-GENERATED DOCUMENTS</p>
            <ProposalDocumentsSection 
                currentProposalId={currentProposalId}
                setShowKnowledgeBaseModal={setShowKnowledgeBaseModal}
                assistantFiles={assistantFiles}
                setShowMfdComponentSelectorModal={setShowMfdComponentSelectorModal}
                proposalDocuments={proposalDocuments}
                setCurrentView={setCurrentView}
                setCurrentGrantDocumentId={setCurrentGrantDocumentId}
                refreshProposalDocuments={refreshProposalDocuments}
            />            
        </div>
    );
}

export default SelectedProposalPage;