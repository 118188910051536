import React from 'react';
import './App.css';
import { AuthProvider } from './AuthContext';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './ApplicationComponents/Home';
import Login from './ApplicationComponents/AuthComponents/Login';
import Signup from './ApplicationComponents/AuthComponents/Signup';

function App() {    
  return (
    <div className="App"> 
      <AuthProvider>
        <Router>
          <Routes>        
            <Route path="/" element={<Home />} />     
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            {/* <Route path="/organization/:ein" element={<OrganizationProfilePage />} /> */}
            <Route path="/opportunities/:opportunityId" element={<Home />} />
            {/* <Route path="/grantmakingprograms/:grantmakingProgramId" element={<Home />} /> */}
            <Route path="/profiles/organization/:ein" element={<Home />} />
            <Route path="/awards/search" element={<Home />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </Router>
      </AuthProvider>       
    </div>
  );
}

export default App;