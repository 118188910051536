import React, { useState, useEffect } from 'react';

// Import AgGrid
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; 
import 'ag-grid-community/styles/ag-theme-alpine.css'; 

// Import loading gif
import loading from '../../assets/loading.gif'

// Import Supabase utilities
import { fetchProgramFitScoresFromSupabase, fetchGrantmakingProgramsFromSupabase } from '../../utilityFunctions/supabaseUtilities';

const RfpsSearch = ({
    currentProgram,
    membershipDetails,
    // setSelectedOpportunityId,
    setSelectedGrantmakingProgramId,
    // setSelectedGrantmakingProgram,
    // selectedGrantmakingProgram
}) => {

    // State variables
    const [opportunities, setOpportunities] = useState([]);
    const [matchScores, setMatchScores] = useState([]);
    const [rowData, setRowData] = useState([]);    

    // useEffect(() => {
    //     console.log("MD from rfps search: ", membershipDetails);
    // }, []);

    // Fetch all RFPs and match scores
    useEffect(() => {
        const fetchGrantmakingPrograms = async () => {
            try {
                // const data = await fetchRFPsFromSupabase();
                const data = await fetchGrantmakingProgramsFromSupabase();
                // Filter out programs where is_donor_advised_fund is True
                const filteredData = data.filter(program => !program.is_donor_advised_fund);
                setOpportunities(filteredData);
            } catch (error) {
                console.error("Error fetching RFPs:", error);
            }
        };

        const fetchScores = async () => {
            if (currentProgram) {
                try {
                    // const data = await fetchRfpScoresFromSupabase(currentProgram.id);
                    console.log("Fetching fit scores for program: ", currentProgram.id);
                    const data = await fetchProgramFitScoresFromSupabase(currentProgram.id);
                    setMatchScores(data);
                } catch (error) {
                    console.error("Error fetching RFP scores:", error);
                }
            }
        };

        fetchGrantmakingPrograms();
        fetchScores();
    }, [currentProgram]);

    // Stitch together grantmaking programs and fit scores

    useEffect(() => {
    if (opportunities.length) {
        const combinedData = opportunities.map(opportunity => {
            const leadScore = matchScores.find(score => score.grantmaking_program_id === opportunity.id);

            return { 
                ...opportunity, 
                leadScore: membershipDetails ? (leadScore ? leadScore.fit_category : '🕐 Loading') : '🔓 Premium',
                // leadScore: leadScore ? leadScore.fit_category : (membershipDetails ? '🕐 Loading' : '🔓 Premium'),
                // leadScoreResponse: leadScore ? leadScore.fit_score : 'Daisy is processing... New opportunities and match scores are updated roughly every week.',
                opportunityCreationTimestamp: opportunity.created_at ? new Date(opportunity.created_at).toLocaleDateString() : 'N/A',
            };
        }).filter(data => data !== null);
        setRowData(combinedData);
        console.log("Row data: ", rowData);
    } else {
        console.log("Missing opportunities or lead scores");
    }
}, [opportunities, matchScores]);

    // When row clicked, set selected opportunity and show opportunity / funder modal
    const handleRowClick = (data) => {
        // console.log("Setting selected opportunity ID: ", data.airtable_id);
        console.log("Setting selected grantmaking program ID: ", data.id);
        // setSelectedOpportunityId(data.airtable_id);
        setSelectedGrantmakingProgramId(data.id);
    };

    const columnDefs = [
        { 
            headerName: "Discovered", 
            field: "opportunityCreationTimestamp", 
            filter: 'agDateColumnFilter',
            valueFormatter: params => new Date(params.value).toLocaleDateString(),
            flex: 1,
            cellStyle: { textAlign: 'left' },
            sortable: true,
            sort: 'desc',
            comparator: (a, b) => new Date(a).getTime() - new Date(b).getTime()
        },
        { 
            headerName: "Grant Name", 
            field: "name", 
            filter: 'agTextColumnFilter',
            flex: 4,
            cellStyle: { textAlign: 'left' } 
        },
        { 
            headerName: "Fit Score", 
            field: "leadScore", 
            filter: 'agTextColumnFilter', 
            flex: 1,
            cellStyle: params => {
                if (params.value === 'Bad Fit') {
                    return { color: 'red', fontWeight: 'bold', textAlign: 'left' };
                } else if (params.value === 'Good Fit') {
                    return { color: 'green', fontWeight: 'bold', textAlign: 'left' };
                }
                return { textAlign: 'left' }; 
            }
        },        

    ];

    // Set selectedGrantmakingProgramId to null on component mount
    useEffect(() => {
        setSelectedGrantmakingProgramId(null);
    }, []);

    return (
        <div className='p-12'>
            <div className='flex gap-4'>
                <p className='text-3xl text-left font-bold text-black'>Grant RFPs</p>
                
            </div>
            <p className='italic text-left text-gray-500 mt-2'>RFPs (requests for proposals) from foundations looking to fund nonprofits like yours.</p>
            <div className="my-8">
                <div className="text-left w-full bg-indigo-100 rounded p-6 flex justify-between items-center">
                    <div>
                        <p className="text-xl font-bold">AI Grant Discovery</p>
                        <p className="text-gray-600 text-left mt-2">Daisy is searching for grant opportunities, and will notify you when she finds RFPs that match your organization. New opportunities / match scores updated roughly every week.</p>
                    </div>
                    <div>
                        <img src={loading} alt="Loading" className='w-24 h-24'/>
                    </div>                    
                </div>                
            </div>
            <div className='flex text-xl items-center mt-10 mb-4'>
                <p className='text-left font-bold mr-4'>Previously Discovered</p>
                <p className='text-indigo-700'>{rowData.length} Results</p>
            </div>
            {rowData.length === 0 ? (
                <div className='w-full p-12 bg-gray-100 text-center text-gray-500 mb-6'>
                    <p className='text-lg font-bold'>Daisy is working on it...</p>
                    <p className='text-sm'>Daisy is searching for RFPs for your organization. You should start seeing results within one week.</p>
            </div>
            ) : (
                <div className="ag-theme-alpine" style={{ width: '100%' }}>
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        onRowClicked={(event) => handleRowClick(event.data)}
                        domLayout='autoHeight'
                    />
                </div>
            )}
        </div>
    );
}

export default RfpsSearch;