import React from 'react';

export default (params) => {
    return (
        <a 
            href={`/profiles/organization/${params.data.recipient_ein}`} 
            rel="noopener noreferrer" 
            style={{ color: '#4338CA', fontWeight: 'bold' }}
        >
            {params.value ? params.value.toLocaleUpperCase() : 'N/A'}
        </a>
    );
};