import React, { useState } from 'react';
import AwardsAdvancedSearchCriteria from './AwardsAdvancedSearchCriteria';
import AwardsAdvancedSearchResults from './AwardsAdvancedSearchResults';

function AwardsAdvancedSearch({
    setSelectedOrganizationEin
}) {    
    
    const [fetchedAwards, setFetchedAwards] = useState([]);

    return (
        <div className="p-12">
            <div className='flex gap-4'>
                <p className="text-3xl font-bold mr-6">Past Awards Search</p>
                <p className='text-3xl text-indigo-700'>{fetchedAwards.length >= 1000 ? '1,000+' : fetchedAwards.length} Results</p>                
            </div>                    
            <p className='italic text-left text-gray-500 mt-2'>Search through our database of grant awards to discover funders for your nonprofit.</p>
            <AwardsAdvancedSearchCriteria
                setFetchedAwards={setFetchedAwards}
                setSelectedOrganizationEin={setSelectedOrganizationEin}
            ></AwardsAdvancedSearchCriteria>        
            <br></br>
            
            <AwardsAdvancedSearchResults 
                fetchedAwards={fetchedAwards}
            ></AwardsAdvancedSearchResults>
        </div>
        
    );
}

export default AwardsAdvancedSearch;