import { useContext, useState, useEffect } from 'react';
import { createProgramInFirestore, updateProgramInFirestore, deleteProgramFromFirestore } from '../../utilityFunctions/firestoreUtilities';
import { AuthContext } from '../../AuthContext';
import { cleanText } from '../../utilityFunctions/uxUtilities';

const CreateProgramModal = ({ 
        currentClientOrganization,
        fetchProgramsForOrganization,
        setCurrentProgram,
        setShowCreateProgramModal,
        existingProgram = null // Optional prop for updating an existing program
    }) => {

    const { authUser } = useContext(AuthContext);
    
    // State for the form values
    const [formValues, setFormValues] = useState({
        programName: '',
        areasServed: '',
        overview: '',
        populationsServed: ''
    });

    
    // if existingProgram is provided, pre-fill the form values
    useEffect(() => {
        if (existingProgram) {
            setFormValues({
                programName: existingProgram.programName,
                areasServed: existingProgram.areasServed,
                overview: existingProgram.overview,
                populationsServed: existingProgram.populationsServed
            });
        }
    }, [existingProgram]);    

    // Delete a program
    const handleDeleteProgram = async (programId) => {
        const confirmed = window.confirm("Are you sure you want to delete this program?");
        if (!confirmed) return;

        await deleteProgramFromFirestore(programId);        
        const refreshedPrograms = await fetchProgramsForOrganization(currentClientOrganization.id);
        setCurrentProgram(refreshedPrograms[0]);
        setShowCreateProgramModal(false)
    }

    // Submit form to create or update a program
    const handleSubmit = async () => {
        const userId = authUser.uid;

        const cleanedFormValues = {
            programName: cleanText(formValues.programName),
            areasServed: cleanText(formValues.areasServed),
            overview: cleanText(formValues.overview),
            populationsServed: cleanText(formValues.populationsServed)
        };

        let newProgram;
        if (existingProgram) {
            await updateProgramInFirestore(existingProgram.id, cleanedFormValues, userId, currentClientOrganization.id);
        } else {
            newProgram = await createProgramInFirestore(cleanedFormValues, userId, currentClientOrganization.id, cleanedFormValues.programName);
        }
        const refreshedPrograms = await fetchProgramsForOrganization(currentClientOrganization.id);
        
        console.log("Refreshed programs: ", refreshedPrograms);
        const updatedProgram = refreshedPrograms.find(program => program.id === (existingProgram ? existingProgram.id : newProgram.id));
        console.log("Updating selected program: ", updatedProgram);
        setCurrentProgram(updatedProgram);
        setShowCreateProgramModal(false);
    };
    
    // Handle form input changes 
    const handleChange = (field, value) => {
        setFormValues(prevValues => ({
            ...prevValues,
            [field]: value
        }));
    };

    return (
        <div 
            className="modal-overlay" 
            style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 9999 }}
            onClick={() => setShowCreateProgramModal(false)}
        >
            <div 
                className="modal p-8 overflow-auto max-h-full" 
                style={{ 
                    cursor: 'default', 
                    width: '80vw', 
                    height: '80vh', 
                    backgroundColor: 'white', 
                    borderRadius: '10px'
                }}
                onClick={(e) => e.stopPropagation()} // Prevent click event from propagating to the overlay
            >
                <p className="text-3xl mt-10 font-bold text-left">{existingProgram ? 'Update Program' : 'Program Details'}</p>                 
                <p className="text-sm mt-2 text-left">Daisy will learn about your program from the following information. <a className="text-indigo-500" href="https://www.notion.so/GrantMagic-Documentation-17546353e32d8076a042c52f7b792c8d?pvs=4#17b46353e32d80408f97ec77e2e5f4ba" target="_blank" rel="noopener noreferrer">Learn More</a></p>
                <div className='text-left mt-10'>
                    
                    <div className="mb-4">
                        <label className="block font-bold mb-2">Program Name <span className="text-red-500">*</span></label>
                        <input 
                            className="w-full p-2 border rounded" 
                            value={formValues.programName}
                            onChange={(e) => handleChange('programName', e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block font-bold mb-2">Overview <span className="text-red-500">*</span></label>
                        <textarea 
                            className="w-full p-2 border rounded" 
                            value={formValues.overview}
                            rows="3"
                            onChange={(e) => handleChange('overview', e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block font-bold mb-2">Areas Served <span className="text-red-500">*</span></label>
                        <textarea 
                            className="w-full p-2 border rounded" 
                            value={formValues.areasServed}
                            rows="3"
                            onChange={(e) => handleChange('areasServed', e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block font-bold mb-2">Populations Served <span className="text-red-500">*</span></label>
                        <textarea 
                            className="w-full p-2 border rounded" 
                            value={formValues.populationsServed}
                            rows="3"
                            onChange={(e) => handleChange('populationsServed', e.target.value)}
                            required
                        />
                    </div>
                    <div className='flex items-center space-x-4 '>
                        <button 
                            className="mt-4 p-2 bg-blue-500 text-white rounded" 
                            onClick={handleSubmit}
                        >
                            {existingProgram ? 'Update' : 'Submit'}
                        </button>
                        <button 
                            className="mt-4 p-2 bg-gray-500 text-white rounded" 
                            onClick={() => setShowCreateProgramModal(false)}
                        >
                            Cancel
                        </button>

                        {/* {existingProgram && (
                            <button 
                                className="mt-4 p-2 text-red-500 rounded" 
                                onClick={() => handleDeleteProgram(existingProgram.id)}
                            >
                                Delete
                            </button>
                        )} */}

                    </div>

                </div>                        
            </div>
        </div>
    );
};

export default CreateProgramModal;