import React from 'react';
import { useState, useEffect } from 'react';
import { fetchMemberEmails, handleAddTeammateToFirestore, handleAddLicenseKeyClicked } from '../../utilityFunctions/firestoreUtilities'; // Import the function

function AccountSettings({
    currentClientOrganization,
    membershipDetails,
    assistantId,
    assistantVectorStoreId,
    assistantFiles
}) {

    const [teammateEmails, setTeammateEmails] = useState([]);

    const handleAddTeammateClicked = async () => {
        await handleAddTeammateToFirestore(currentClientOrganization)        
        const fetchedEmails = await fetchMemberEmails(currentClientOrganization);
        setTeammateEmails(fetchedEmails);
    }

    const fetchEmails = async () => {
        if (currentClientOrganization) {
            const fetchedEmails = await fetchMemberEmails(currentClientOrganization);
            setTeammateEmails(fetchedEmails);
        }
    };

    useEffect(() => {
        fetchEmails();
    }, [currentClientOrganization]);

    return (
        <div className='flex flex-col justify-center p-12'>
            <p className="text-3xl mt-10 font-bold text-left">Account Settings</p>                 
            <div className='text-left mt-10'>
                {/* Plan */}
                <div>
                    <p className='text-left mt-8 text-sm font-bold text-gray-500'>ORGANIZATION</p>
                    <div className='bg-gray-100 rounded text-left p-3 text-sm my-2'>
                        <p>Current Organization: <span className="text-indigo-500 font-bold">{currentClientOrganization.name}</span></p> 
                        <p>Organization ID: <span className="text-indigo-500 font-bold">{currentClientOrganization.id}</span></p> 
                    </div>
                    <p className='text-left mt-8 text-sm font-bold text-gray-500'>PLAN TYPE</p>
                    <div className='bg-gray-100 rounded text-left p-3 text-sm my-2'>
                        {membershipDetails 
                            ? 
                                <div>
                                    <p>Current Plan: <span className="text-indigo-500 font-bold">Premium</span></p> 
                                    <p>The Premium plan includes unlimited access to our Grant Database and Grant Discovery features.</p>
                                    <p className='text-gray-500 italic text-sm mt-6'>To make changes to your subscription, contact Support.</p>
                                </div>                    
                            :  
                                <div>
                                    <p>Current Plan: <span className="text-gray-500 font-bold">Free</span></p>                                     
                                    <p>To get access to our grant database functionality, <a className='text-indigo-500 font-bold' href="https://satodadj.gumroad.com/l/rlqzat" target="_blank" rel="noopener noreferrer">upgrade here</a>, then add your license key using the button below.</p>

                                    <button 
                                        className="bg-indigo-500 text-white py-2 px-4 rounded mt-4"
                                        onClick={() => handleAddLicenseKeyClicked(currentClientOrganization.id)}
                                    >
                                        Add License Key
                                    </button>                                    
                                </div>
                        }                                
                    </div>
                </div>                                
                
                {/* Sharing */}
                <div>
                    <p className='text-left mt-8 text-sm font-bold text-gray-500'>SHARING</p>
                    <div className='bg-gray-100 rounded text-left p-3 text-sm my-2'>
                        {currentClientOrganization && (
                            <div>
                                <p className=''>Team Members</p>
                                <ul>
                                    {teammateEmails.map((email, index) => (
                                        <li className='ml-4' key={index}>- {email}</li>
                                    ))}
                                </ul>                                
                                
                                <button 
                                    className='bg-indigo-500 p-2 text-white rounded-lg mt-4'
                                    onClick={handleAddTeammateClicked}
                                >
                                    Add Teammate
                                </button>
                                <p className='mt-6 italic text-gray-500 text-sm'>
                                    To add a teammate, please refer to our <a className='text-indigo-500 font-bold' href="https://www.notion.so/GrantMagic-FAQ-e2163416b25641e3babcafc3163a0032?pvs=4#c6008634f7614a1ea49411cd73641134" target="_blank" rel="noopener noreferrer">FAQ</a> for detailed instructions. Contact support if any assistance needed in managing team member access.
                                </p>
                            </div>                                                
                            
                        )}       
                        
                    </div>                    
                </div>

                <div>
                    <p className='text-left mt-8 text-sm font-bold text-gray-500'>DEBUGGING INFO</p>
                    <div className='bg-gray-100 rounded text-left p-3 text-sm my-2 mb-10'>    
                        <p>Current Organization: {currentClientOrganization.name}</p>
                        <p>Assistant ID: {assistantId}</p>
                        <p>Assistant Vector Store ID: {assistantVectorStoreId}</p>
                        <p>Assistant Files: {JSON.stringify(assistantFiles)}</p>
                    </div>
                </div>

            </div>
        </div>                    
  );
}

export default AccountSettings;